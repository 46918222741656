import React, { useCallback, useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from 'formik';
import { Button, Col, Form, Row, message, Typography, Select, Input, Upload, Spin, Space } from "antd";
import { CircularProgress } from "@mui/material";
import { UploadOutlined } from '@ant-design/icons';
import { useAuth } from "../../../../hooks/useAuth";
import { useSolicitudPer } from "../../../../hooks/administrativa/solicitud-permisos/useSolicitudPer";
import DatePicker from 'react-datepicker';
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

export function SolicitudPermisosForm(props) {
    
    const { auth } = useAuth();
    const today = new Date();
    const { activepane } = props;
    const { addSolicitudPer, getBossUser, Respuesta, Boss } = useSolicitudPer();
    const [fileList, setFileList] = useState([]);
    const [spinning, setSpinning] = useState(false);
    const [readonly, setReadonly] = useState(false);
    
    const idUser = auth.me?.id_user;
    const jefeInmediato = auth.me?.jefe_inmediato;

    const { Option } = Select;
    const { TextArea } = Input;
    const { Text } = Typography;

    // Maneja el estado del spin
    useEffect(() => {
        if (Respuesta !== null) {
            setSpinning(false)
            formik.resetForm();
            activepane("2");
            message.success(Respuesta);
        }
    }, [Respuesta]);

    const formik = useFormik({
      initialValues: initialValues(idUser),
      validationSchema: newSchema,
      onSubmit: async (formValues) => {
        
        setSpinning(true)
        try {
            await addSolicitudPer( formValues );
        } catch (error) {
            if (error?.message) {
                message.error(error.message);
            }
        }
      },
      onReset: () => {
        setFileList([]);
      }
    });

    useEffect(() => {
        if (formik.values.motivo === "Salud") {
            formik.setFieldValue('reposicion', 'La reposición de tiempo no aplica para permisos de SALUD');
            setReadonly(true)
        } else {
            formik.setFieldValue('reposicion', '');
            setReadonly(false)
        }
    }, [formik.values.motivo]);

    useEffect(() => {
        async function fetchData() {
          if (Boss == null) {
            await getBossUser(jefeInmediato);
          }else{
            formik.setFieldValue('jefeinmediato', Boss.id_user);
          }
        }
        fetchData();
      }, [Boss]);

    // Reestringe el tipo de archivo que se puede adjuntar
    const handleChange = (file) => {
        const isValidType = /\.(pdf|png|jpeg|jpg)$/i.test(file.name);
        if (!isValidType) {
        message.error('Solo se permiten archivos .pdf, .png, .jpeg, .jpg');
        return Upload.LIST_IGNORE; // Ignora el archivo adjuntado en el formulario
        }

        formik.setFieldValue('archivo', file); // Añade el archivo en el campo de formik de "archivo"
        setFileList([file]); // Actualiza el estado con el archivo
        return false; // Previene la subida automática del archivo
    };

    // Especifico el rango de hora seleccionable
    const minTime = moment('07:30 AM', 'hh:mm A').toDate();
    const maxTime = moment('04:30 PM', 'hh:mm A').toDate();
  
    // Se calcula el tiempo maximo de la hora fin, tiene en cuenta el valor de la hora inicio mas 4 horas maximas
    const horaIniDate = formik.values.hora_ini ? moment(formik.values.hora_ini, 'hh:mm:ss A').toDate() : null;
    const minTimeFin = horaIniDate ? moment(horaIniDate).add(1, 'minute').toDate() : null;
    const maxTimeFin = horaIniDate ? moment(horaIniDate).add(4, 'hours').toDate() : null;

    const filterDate = date => {
        // verifica si la fecha es menor a hoy para deshabilitarlas
        if (date < today && date.toDateString() !== today.toDateString()) return false;
        
        // Verifica si es un domingo (0 es el código del día domingo en JavaScript)
        return date.getDay() !== 0;
      };

    return (
        <>
            <Text 
                style={{ 
                    fontWeight: 'bold', 
                    fontFamily: '-moz-initial', 
                    fontSize: '30px',  
                }}>
                    Solicitud de permiso laboral <strong style={{ color: '#CC302B' }}>SYZ</strong>
            </Text><br />
            <Text 
                style={{ 
                    fontFamily: '-moz-initial', 
                }}>
                    <strong style={{ fontWeight: 'bold' }}> Usuario: </strong>{auth.me?.full_name}<br />
                    <strong style={{ fontWeight: 'bold' }}> Área: </strong>{auth.me?.area}<br />
                    <strong style={{ fontWeight: 'bold' }}> Cargo: </strong>{auth.me?.cargo}
            </Text>
            <br/><br/>

            <Form layout="vertical" onFinish={formik.handleSubmit}>
                <Row gutter={5} >
                    <Col span={4}>
                        <Form.Item
                            label="Tipo permiso"
                            validateStatus={
                                formik.errors.motivo && formik.touched.motivo ? "error" : ""
                            }
                            help={
                                formik.touched.motivo && formik.errors.motivo
                                ? formik.errors.motivo
                                : ""
                            }
                        >
                        <Select
                            name="motivo"
                            value={formik.values.motivo}
                            onChange={(value) => formik.setFieldValue('motivo', value)} // Actualiza el valor en formik
                            onBlur={formik.handleBlur}
                            placeholder="Selecciona una opción"
                            defaultValue={""}
                        >
                            <Option value="Personal">Personal</Option>
                            <Option value="Educación">Educación</Option>
                            <Option value="Salud">Salud</Option>
                        </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Cédula del trabajador"
                            validateStatus={
                                formik.errors.cedula && formik.touched.cedula ? "error" : ""
                            }
                            help={
                                formik.touched.cedula && formik.errors.cedula
                                ? formik.errors.cedula
                                : ""
                            }
                        >
                        <Input
                            name="cedula"
                            value={formik.values.cedula}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            style={{ maxWidth: '100%'}}
                        />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={5}>
                    <Col span={4}>
                        <Form.Item
                            label="Fecha permiso"
                            validateStatus={ formik.errors.fecha_permiso && formik.touched.fecha_permiso
                                ? "error": ""}
                            help={ formik.touched.fecha_permiso && formik.errors.fecha_permiso 
                                ? formik.errors.fecha_permiso : "" }
                        >
                        <DatePicker
                            selected={formik.values.fecha_permiso ? new Date(formik.values.fecha_permiso + 'T00:00:00') : null}
                            onChange={(date) => {
                                const formattedDate = date ? format(date, 'yyyy-MM-dd') : '';
                                formik.setFieldValue('fecha_permiso', formattedDate);
                            }}
                            dateFormat="yyyy-MM-dd"
                            autoComplete="off"
                            filterDate={filterDate} // Añade la función filterDate aquí
                            customInput={
                                <Input
                                    label="Fecha inicial"
                                    fullWidth
                                    value={formik.values.fecha_permiso || ''}
                                    autoComplete="off"
                                />
                            }
                        />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                        label="Hora inicial"
                        validateStatus={formik.errors.hora_ini && formik.touched.hora_ini ? "error" : ""}
                        help={formik.touched.hora_ini && formik.errors.hora_ini ? formik.errors.hora_ini : ""}
                        >
                        <DatePicker
                            selected={horaIniDate}
                            onChange={(date) => formik.setFieldValue('hora_ini', moment(date).format('hh:mm:ss A'))}
                            onBlur={() => formik.setFieldTouched('hora_ini')}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={1}
                            timeCaption="Hora"
                            dateFormat="hh:mm:ss"
                            minTime={minTime}
                            maxTime={maxTime}
                            customInput={
                            <Input
                                label="Hora inicial"
                                fullWidth
                                value={formik.values.hora_ini ? moment(formik.values.hora_ini, 'hh:mm:ss A').format('hh:mm:ss') : '' || ''}
                                autoComplete="off"
                                readOnly
                            />
                            }
                        />
                        </Form.Item>
                    </Col>

                    <Col span={4}>
                        <Form.Item
                            label="Hora fin"
                            validateStatus={formik.errors.hora_fin && formik.touched.hora_fin ? "error" : ""}
                            help={formik.touched.hora_fin && formik.errors.hora_fin ? formik.errors.hora_fin : ""}
                            >
                            <DatePicker
                                selected={formik.values.hora_fin ? moment(formik.values.hora_fin, 'hh:mm:ss A').toDate() : null}
                                onChange={(date) => formik.setFieldValue('hora_fin', moment(date).format('hh:mm:ss A'))}
                                onBlur={() => formik.setFieldTouched('hora_fin')}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={1}
                                timeCaption="Hora"
                                dateFormat="hh:mm:ss"
                                minTime={minTimeFin}
                                maxTime={formik.values.motivo === 'Salud' ? moment().endOf('day').toDate() : maxTimeFin}
                                disabled={!formik.values.hora_ini}  // Desactiva el picker si no hay hora_ini
                                customInput={
                                <Input
                                    label="Hora final"
                                    fullWidth
                                    value={formik.values.hora_fin ? moment(formik.values.hora_fin, 'hh:mm:ss A').format('hh:mm:ss') : '' || ''}
                                    autoComplete="off"
                                    readOnly
                                />
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={5} >
                    <Col span={5}>
                        <Form.Item
                            label="Jefe Aprobador"
                            validateStatus={
                                formik.errors.jefeinmediato && formik.touched.jefeinmediato ? "error" : ""
                            }
                            help={
                                formik.touched.jefeinmediato && formik.errors.jefeinmediato
                                ? formik.errors.jefeinmediato
                                : ""
                            }
                        >
                        {Boss == null ? (
                            <CircularProgress size={25}/>
                        ) : (
                            <Input
                                name="jefeinmediato"
                                value={Boss.full_name}
                                readOnly
                                disabled
                            />
                        )}
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={5} >
                    <Col span={12}>
                        <Form.Item
                            label="Reposición"
                            validateStatus={
                            formik.errors.reposicion && formik.touched.reposicion ? "error" : ""
                            }
                            help={
                            formik.touched.reposicion && formik.errors.reposicion
                                ? formik.errors.reposicion
                                : ""
                            }
                        >
                            <TextArea
                                name="reposicion"
                                rows={5}
                                autoSize 
                                value={formik.values.reposicion}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                style={{ maxWidth: '100%'}}
                                readOnly={readonly}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={5} >
                    <Col span={12}>
                        <Form.Item
                            label="Descripción"
                            validateStatus={
                            formik.errors.descripcion && formik.touched.descripcion ? "error" : ""
                            }
                            help={
                            formik.touched.descripcion && formik.errors.descripcion
                                ? formik.errors.descripcion
                                : ""
                            }
                        >
                            <TextArea
                                name="descripcion"
                                rows={5}
                                autoSize 
                                value={formik.values.descripcion}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                style={{ maxWidth: '100%'}}
                            />
                        </Form.Item>
                    </Col>
                </Row>


                <Row gutter={5}>
                    <Col span={12}>
                        <Form.Item
                            validateStatus={
                            formik.errors.archivo && formik.touched.archivo ? "error" : ""
                            }
                            help={
                            formik.touched.archivo && formik.errors.archivo
                                ? formik.errors.archivo
                                : ""
                            }
                        >
                            <Upload
                                name="archivo"
                                accept=".pdf,.png,.jpeg,.jpg"
                                fileList={fileList}
                                beforeUpload={handleChange}
                                maxCount={1}
                                onRemove={() => {
                                    formik.setFieldValue('archivo', null);
                                    setFileList([]);
                                }}
                            >
                                <Button icon={<UploadOutlined />}>Adjuntar soporte</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={12} >   
                    <Col span={9}>
                        <Form.Item>

                            <Button 
                                type="primary"
                                htmlType="submit" 
                                style={{ marginRight: '20px', top: '10px' }}
                            >
                                Enviar
                            </Button>

                            <Button 
                                type="primary" 
                                danger 
                                style={{ top: '10px' }} 
                                onClick={() => { formik.handleReset() }}  
                            >
                                Limpiar campos
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>

            <Spin spinning={spinning} fullscreen />
        </>
    )
}

// Función para inicializar los valores del formulario
function initialValues(idUser) {
    return {
        id_user: idUser,
        motivo: "",
        fecha_permiso: null, 
        hora_ini: null,
        hora_fin: null,
        archivo: null,
        reposicion: "",
        jefeinmediato: null,
        cedula: "",
        descripcion: "",
    };
}

// Función para definir el esquema de validación del formulario
const newSchema = Yup.object().shape({
    motivo: Yup.string().required("El campo es requerido"),
    fecha_permiso: Yup.date().required("El campo es requerido"),
    hora_ini: Yup.string().required("El campo es requerido"),
    hora_fin: Yup.string().required("El campo es requerido"),
    reposicion: Yup.string().required("El campo es requerido"),
    cedula: Yup.string().required("El campo es requerido"),
    descripcion: Yup.string().required("El campo es requerido"),
    archivo: Yup.mixed().nullable().test(
      'archivo-requerido',
      'El soporte es requerido para motivos de salud',
      function (value) {
        const { motivo } = this.parent; // Obteniendo el valor de 'motivo' dentro del schema de validación
        if (motivo === 'Salud') {
          return value !== null; // Valida que haya un archivo si el motivo es "Salud"
        }
        return true; // No requerido si el motivo no es "Salud"
      }
    ),
});
  

export function ChangeStatusForBoss(props) {
    const { data, onClose, onRefetch } = props;
    const { updateStatusAprovJefe, Respuesta } = useSolicitudPer();
    const { TextArea } = Input
    const [loading, setLoading] = useState({
        aprobar: false,
        rechazar: false,
    });

    // Maneja el estado del spin
    useEffect(() => {
        if (Respuesta !== null) {
            message.success(Respuesta);
        }
    }, [Respuesta]);

    const formik = useFormik({
        initialValues: {
            aprobadojefe: data.aprobadojefe, // Estado actual del formulario
            observacion: ""
        },
        validationSchema: newSchemaStatusBoss,
        onSubmit: async (values) => {

            try {
                let nuevoEstado;

                if (values.aprobadojefe === 1) {
                    nuevoEstado = 1;
                    setLoading({ ...loading, aprobar: true });
                } else if (values.aprobadojefe === 2) {
                    nuevoEstado = 2;
                    setLoading({ ...loading, rechazar: true });
                }
                
                await updateStatusAprovJefe(data.Id_permiso, data.id_user, nuevoEstado, values);

                onRefetch();
                onClose();
            } catch (error) {
                message.error('Error al actualizar el estado del formulario');
            } finally {
                setLoading({ aprobar: false, rechazar: false });
            }
        }
    });

    return (
        <>
        <Form onFinish={formik.handleSubmit} style={{ width: 400 }}>
                    <Form.Item
                        label="Observación"
                        validateStatus={
                        formik.errors.observacion && formik.touched.observacion ? "error" : ""
                        }
                        help={
                        formik.touched.observacion && formik.errors.observacion
                            ? formik.errors.observacion
                            : ""
                        }
                    >
                        <TextArea
                            name="observacion"
                            rows={5}
                            autoSize 
                            value={formik.values.observacion}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            style={{ maxWidth: '100%'}}
                        />
                    </Form.Item>
            <center>
                <Space>
                    <Button
                        type="primary"
                        style={{ background: "#60C000" }}
                        htmlType="submit"
                        onClick={() => formik.setFieldValue('aprobadojefe', 1)}
                        loading={loading.aprobar}
                    >
                        Aprobar
                    </Button>
                    <Button
                        type="primary"
                        style={{ background: "rgb(204, 48, 43)" }}
                        htmlType="submit"
                        onClick={() => formik.setFieldValue('aprobadojefe', 2)}
                        loading={loading.rechazar}
                    >
                        Rechazar
                    </Button>
                </Space>
            </center>
        </Form>

        </>
    );
}

const newSchemaStatusBoss = Yup.object().shape({
    observacion: Yup.string().required("Este campo es requerido"),
});

export function ChangeStatusForGH(props) {
    const { data, onClose, onRefetch } = props;
    const { updateStatusAprovGH, Respuesta } = useSolicitudPer();
    const { auth } = useAuth();

    const userMe = auth.me?.id_user

    const [loading, setLoading] = useState({
        aprobar: false,
        rechazar: false,
    });

    // Maneja el estado del spin
    useEffect(() => {
        if (Respuesta !== null) {
            message.success(Respuesta);
        }
    }, [Respuesta]);

    const formik = useFormik({
        initialValues: {
            aprobadogh: data.aprobadogh // Estado actual del formulario
        },
        onSubmit: async (values) => {

            try {
                let nuevoEstado;

                if (values.aprobadogh === 1) {
                    nuevoEstado = 1;
                    setLoading({ ...loading, aprobar: true });
                } else if (values.aprobadogh === 2) {
                    nuevoEstado = 2;
                    setLoading({ ...loading, rechazar: true });
                }
                
                await updateStatusAprovGH(data.Id_permiso, data.id_user, userMe, nuevoEstado);

                onRefetch();
                onClose();
            } catch (error) {
                message.error('Error al actualizar el estado del formulario');
            } finally {
                setLoading({ aprobar: false, rechazar: false });
            }
        }
    });

    return (
        <>
        <Form onFinish={formik.handleSubmit}>
            <Space>
                <Button
                    type="primary"
                    style={{ background: "#60C000" }}
                    htmlType="submit"
                    onClick={() => formik.setFieldValue('aprobadogh', 1)}
                    loading={loading.aprobar}
                >
                    Aprobar
                </Button>
                <Button
                    type="primary"
                    style={{ background: "rgb(204, 48, 43)" }}
                    htmlType="submit"
                    onClick={() => formik.setFieldValue('aprobadogh', 2)}
                    loading={loading.rechazar}
                >
                    Rechazar
                </Button>
            </Space>
        </Form>

        </>
    );
}