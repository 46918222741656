import { Button, Col, Form, Input, Row, message, DatePicker, Select } from "antd";
import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import { useSurveysOrdersInvoices } from "../../hooks/useSurveysOrdersInvoices";
import { FormStyle } from "../comons/styles";
import moment from "moment";

export function SurveysOrdersInvoicesForm(props) {
  const { onClose, onRefetch, SurveysOrdersInvoices } = props;
  const { addSurveysOrdersInvoices, updateSurveysOrdersInvoices } = useSurveysOrdersInvoices();
  const { Option } = Select;

  const formik = useFormik({
    initialValues: initialValues(SurveysOrdersInvoices), // Utiliza la función getInitialValues
    validationSchema: Yup.object(
      SurveysOrdersInvoices ? updateSchema() : newSchema()
    ),
    onSubmit: async (formValues) => {
      try {
        if (SurveysOrdersInvoices)
          await updateSurveysOrdersInvoices(
            SurveysOrdersInvoices.id_encuestas_pf,
            formValues
          );
        else await addSurveysOrdersInvoices(formValues);

        onRefetch();
        onClose();
        message.success("Operación exitosa");
      } catch (error) {
        if (error?.message) {
          message.error(error.message);
        } else {
          onClose();
        }
      }
    },
  });

  return (
    <Form layout="vertical" onFinish={formik.handleSubmit} style={FormStyle}>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label="Empresa"
            validateStatus={
              formik.errors.empresa && formik.touched.empresa ? "error" : ""
            }
            help={
              formik.touched.empresa && formik.errors.empresa
                ? formik.errors.empresa
                : ""
            }
          >
            <Input
              name="empresa"
              value={formik.values.empresa}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Nombres y Apellidos"
            validateStatus={
              formik.errors.nombres_apellidos &&
              formik.touched.nombres_apellidos
                ? "error"
                : ""
            }
            help={
              formik.touched.nombres_apellidos &&
              formik.errors.nombres_apellidos
                ? formik.errors.nombres_apellidos
                : ""
            }
          >
            <Input
              name="nombres_apellidos"
              value={formik.values.nombres_apellidos}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Cargo"
            validateStatus={
              formik.errors.cargo && formik.touched.cargo ? "error" : ""
            }
            help={
              formik.touched.cargo && formik.errors.cargo
                ? formik.errors.cargo
                : ""
            }
          >
            <Input
              name="cargo"
              value={formik.values.cargo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
      </Row>
     
      <Row gutter={16}>
        
      <Col span={8}>
          <Form.Item
            label="Correo"
            validateStatus={
              formik.errors.correo && formik.touched.correo ? "error" : ""
            }
            help={
              formik.touched.correo && formik.errors.correo
                ? formik.errors.correo
                : ""
            }
          >
            <Input
              name="correo"
              value={formik.values.correo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Celular"
            validateStatus={
              formik.errors.celular && formik.touched.celular ? "error" : ""
            }
            help={
              formik.touched.celular && formik.errors.celular
                ? formik.errors.celular
                : ""
            }
          >
            <Input
              name="celular"
              value={formik.values.celular}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Documento"
            validateStatus={
              formik.errors.documento && formik.touched.documento ? "error" : ""
            }
            help={
              formik.touched.documento && formik.errors.documento
                ? formik.errors.documento
                : ""
            }
          >
            <Input
              name="documento"
              value={formik.values.documento}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} >
        <Col span={8}>
          <Form.Item
            label="Fecha Documento"
            validateStatus={
              formik.errors.fecha_documento && formik.touched.fecha_documento
                ? "error"
                : ""
            }
            help={
              formik.touched.fecha_documento && formik.errors.fecha_documento
                ? formik.errors.fecha_documento
                : ""
            }
          >
            <DatePicker
              name="fecha_documento"
              style={{width: '100%'}}
              value={formik.values.fecha_documento ? moment(formik.values.fecha_documento) : null}
              onChange={(date, dateString) => formik.setFieldValue('fecha_documento', dateString)}
              onBlur={() => formik.setFieldTouched('fecha_documento')}
            />
          </Form.Item>
        </Col>
    <Col span={8}>
    <Form.Item
      label="¿Qué tan difícil o fácil fue resolver su solicitud con SYZ?"
      validateStatus={
        formik.errors.p9 && formik.touched.p9 ? "error" : ""
      }
      help={
        formik.touched.p9 && formik.errors.p9
          ? formik.errors.p9
          : ""
      }
    >
      <Select
        name="p9"
        value={formik.values.p9}
        onChange={(value) => formik.setFieldValue('p9', value)} // Actualiza el valor en formik
        onBlur={formik.handleBlur}
        placeholder="Selecciona una opción"
      >
        <Option value="1">1</Option>
        <Option value="2">2</Option>
        <Option value="3">3</Option>
        <Option value="4">4</Option>
        <Option value="5">5</Option>
        <Option value="6">6</Option>
        <Option value="7">7</Option>
        <Option value="8">8</Option>
        <Option value="9">9</Option>
        <Option value="10">10</Option>
      </Select>
    </Form.Item>
  </Col>
  <Col span={8}>
    <Form.Item
      label="¿Qué tanto recomendaria nuestra marca?"
      validateStatus={
        formik.errors.p10 && formik.touched.p10 ? "error" : ""
      }
      help={
        formik.touched.p10 && formik.errors.p10
          ? formik.errors.p10
          : ""
      }
    >
      <Select
        name="p10"
        value={formik.values.p10}
        onChange={(value) => formik.setFieldValue('p10', value)} // Actualiza el valor en formik
        onBlur={formik.handleBlur}
        placeholder="Selecciona una opción"
      >
        <Option value="1">1</Option>
        <Option value="2">2</Option>
        <Option value="3">3</Option>
        <Option value="4">4</Option>
        <Option value="5">5</Option>
        <Option value="6">6</Option>
        <Option value="7">7</Option>
        <Option value="8">8</Option>
        <Option value="9">9</Option>
        <Option value="10">10</Option>
      </Select>
    </Form.Item>
  </Col>
</Row>

<Row gutter={25}>
  <Col span={24}>
    <Form.Item
      label="¿Tiene usted algún comentario, felicitación, queja o reclamo respecto al servicio?"
      validateStatus={
        formik.errors.p11 && formik.touched.p11 ? "error" : ""
      }
      help={
        formik.touched.p11 && formik.errors.p11
          ? formik.errors.p11
          : ""
      }
    >
      <Input
        name="p11"
        value={formik.values.p11}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
    </Form.Item>
  </Col>
</Row>


      <Form.Item>
        <Button type="primary" htmlType="submit">
          {SurveysOrdersInvoices ? "Actualizar" : "Crear"}
        </Button>
      </Form.Item>
    </Form>
  );
}

// Función para inicializar los valores del formulario
function initialValues(data) {
  return {
    empresa: data?.empresa || "",
    nombres_apellidos: data?.nombres_apellidos || "",
    cargo: data?.cargo || "",
    correo: data?.correo || "",
    celular: data?.celular || "",
    documento: data?.documento || null,
    fecha_documento: data?.fecha_documento || null,
    /* fecha_encuesta: data?.fecha_encuesta || "",
    p1: data?.p1 || "",
    p2: data?.p2 || "",
    p3: data?.p3 || "",
    p4: data?.p4 || "",
    p5: data?.p5 || "",
    p6: data?.p6 || "",
    p7: data?.p7 || "",
    p8: data?.p8 || "", */
    p9: data?.p9 || "",
    p10: data?.p10 || "",
    p11: data?.p11 || "",
  };
}

// Función para definir el esquema de validación del formulario
function newSchema() {
  return {
    empresa: Yup.string().required("El campo de empresa es requerido"),
    nombres_apellidos: Yup.string().required("El campo de nombres y apellidos es requerido"),
    cargo: Yup.string().required("El campo de cargo es requerido"),
    correo: Yup.string().email("Ingrese un correo electrónico válido").required("El campo de correo es requerido"),
    celular: Yup.string().required("El campo de celular es requerido"),
    fecha_documento: Yup.date().required("El campo de fecha de documento es requerido"),
    //documento: Yup.number().required("El campo de documento es requerido"),
    /* fecha_encuesta: Yup.date().required("El campo de fecha de encuesta es requerido"),
    p1: Yup.string().required("El campo de p1 es requerido"),
    p2: Yup.string().required("El campo de p2 es requerido"),
    p3: Yup.string().required("El campo de p3 es requerido"),
    p4: Yup.string().required("El campo de p4 es requerido"),
    p5: Yup.string().required("El campo de p5 es requerido"),
    p6: Yup.string().required("El campo de p6 es requerido"),
    p7: Yup.string().required("El campo de p7 es requerido"),
    p8: Yup.string().required("El campo de p8 es requerido"), */
    p9: Yup.string().required("El campo es requerido"),
    p10: Yup.string().required("El campo es requerido"),
    p11: Yup.string().required("El campo es requerido"),

  };
}

function updateSchema() {
  return {
    empresa: Yup.string().required("El campo de empresa es requerido"),
    nombres_apellidos: Yup.string().required("El campo de nombres y apellidos es requerido"),
    cargo: Yup.string().required("El campo de cargo es requerido"),
    correo: Yup.string().email("Ingrese un correo electrónico válido").required("El campo de correo es requerido"),
    celular: Yup.string().required("El campo de celular es requerido"),
    //documento: Yup.number().required("El campo de documento es requerido"),
    fecha_documento: Yup.date().required("El campo de fecha de documento es requerido"),
    /* fecha_encuesta: Yup.date().required("El campo de fecha de encuesta es requerido"),
    p1: Yup.string().required("El campo de p1 es requerido"),
    p2: Yup.string().required("El campo de p2 es requerido"),
    p3: Yup.string().required("El campo de p3 es requerido"),
    p4: Yup.string().required("El campo de p4 es requerido"),
    p5: Yup.string().required("El campo de p5 es requerido"),
    p6: Yup.string().required("El campo de p6 es requerido"),
    p7: Yup.string().required("El campo de p7 es requerido"),
    p8: Yup.string().required("El campo de p8 es requerido"), */
    p9: Yup.string().required("El campo es requerido"),
    p10: Yup.string().required("El campo es requerido"),
    p11: Yup.string().required("El campo es requerido"),
  };
}
