import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Button,
  Card,
  Container,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popover,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import { ButtonStyle, CellStyle } from "./styles";

export function TablaBasecomons(props) {
  const {
    Data,
    handleAgregarData,
    handleEditarData,
    handleEliminarData,
    columns,
  } = props;

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [searchTerm, setSearchTerm] = useState("");
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handlePopoverOpen = (event, data) => {
    setPopoverAnchorEl(event.currentTarget);
    setSelectedData(data);
  };

  const handledetalles = (event, data) => {
    if (selectedRow === data) {
      setSelectedRow(null);
    } else {
      setSelectedRow(data);
    }
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
    setSelectedData(null);
    setSelectedRow(null);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const filteredData =
    Data &&
    Data.filter(
      (data) =>
        data.nombre_archivo &&
        data.nombre_archivo.toLowerCase().includes(searchTerm.toLowerCase())
    );

  const rowsToShow =
    filteredData &&
    filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const open = Boolean(popoverAnchorEl);
  const popoverId = open ? "Data-popover" : undefined;

  

  return (
    <Container>
      <Card
        sx={{
          borderRadius: "12px",
          padding: "16px",
          marginBottom: "16px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TextField
          label="Buscar por nombre de video"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <Button
          variant="contained"
          style={ButtonStyle}
          startIcon={<AddIcon />}
          onClick={() => handleAgregarData(selectedRow)}
        >
          Agregar
        </Button>
      </Card>
      <Card>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                
                {columns.map((header) => (
                  <TableCell key={header.field}>
                    <Typography style={CellStyle}>{header.label}</Typography>
                  </TableCell>
                ))}
                <TableCell>
                  <Typography style={CellStyle}>Acciones</Typography>
                </TableCell>
              </TableRow>
              {rowsToShow &&
                rowsToShow.map((data) => (
                  <React.Fragment key={data.id_archivo}>
                    <TableRow>
                      
                      {columns.map((header) => (
                        <TableCell style={CellStyle} key={header.field}>
                          {data[header.field]}
                        </TableCell>
                      ))}
                      <TableCell>
                        <IconButton
                          onClick={(event) => handlePopoverOpen(event, data)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[100, 200, 500]}
          component="div"
          count={filteredData ? filteredData.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={popoverAnchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List>
        {handleEditarData && (
          <ListItemButton onClick={() => handleEditarData(selectedData)}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText primary="Editar" />
          </ListItemButton>
           )}
           {handleEliminarData && (
          <ListItemButton onClick={() => handleEliminarData(selectedData)}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText primary="Eliminar" />
          </ListItemButton>
          )}
        </List>
      </Popover>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Acción realizada con éxito"
      />
    </Container>
  );
}
