import React, { useEffect } from "react";
import * as Yup from "yup";
import { Button, Col, Form, Input, Row, message, Select, Table, Spin } from "antd";
import { useFormik } from "formik";
import { FormStyle } from "../../../comons/styles";
import { useInspeccionAltura } from "../../../../hooks/administrativa/formularios-sig/useInspeccionAlturas";
import { initialValuesInspeccionAltura } from "../components/initialValues";
import { CircularProgress } from "@mui/material";

export function InspeccionAlturaForm(props) {

  const { updateInspeccionAlturas, cancelLoader, loading } = useInspeccionAltura();
  const { dataTipoFormulario, data, handleNext, handlePrev, id, setnewdataInspeccionAltura } = props;
  const { Option } = Select;

  useEffect(() => {
    cancelLoader()
  }, [data])

  const formik = useFormik({
    initialValues: initialValuesInspeccionAltura(id.Id_formulario, data),
    //validationSchema: analisisTrabajoSchema,

    onSubmit: async (formValues) => {
      try {
        await updateInspeccionAlturas(id.Id_inspeccionaltura, formValues);
        message.success("Actualización exitosa (Inspección de alturas)");
        setnewdataInspeccionAltura(formValues)
        handleNext();
      } catch (error) {
        message.error("Error en la operación (Inspección de alturas)");
      }
    },
  });

  return (
    <>
    {loading ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <CircularProgress />
      </div>
    ) : (
    <Form layout="vertical" onFinish={formik.handleSubmit} style={FormStyle}>
      <Row gutter={16}><Col span={12} style={{fontSize:'20px', fontWeight: 'bold', color: 'rgb(204, 48, 43)'}}>Tejidos y correas</Col></Row>
      <hr/>
      <Row gutter={16}>
        <Col span={10}>
          <Form.Item
              label="Fibras externas cortadas, desgastadas, desgarradas"
              validateStatus={formik.errors.fibrasexternas && formik.touched.fibrasexternas ? "error" : ""}
              help={formik.touched.fibrasexternas && formik.errors.fibrasexternas ? formik.errors.fibrasexternas : ""}
          >
            <Select
                name="fibrasexternas"
                value={formik.values.fibrasexternas}
                onChange={(value) => formik.setFieldValue('fibrasexternas', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Cortes o rotura del tejido o costuras"
              validateStatus={formik.errors.cortes && formik.touched.cortes ? "error" : ""}
              help={formik.touched.cortes && formik.errors.cortes ? formik.errors.cortes : ""}
          >
            <Select
                name="cortes"
                value={formik.values.cortes}
                onChange={(value) => formik.setFieldValue('cortes', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
              label="Grietas"
              validateStatus={formik.errors.grietas && formik.touched.grietas ? "error" : ""}
              help={formik.touched.grietas && formik.errors.grietas ? formik.errors.grietas : ""}
          >
            <Select
                name="grietas"
                value={formik.values.grietas}
                onChange={(value) => formik.setFieldValue('grietas', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={7}>
          <Form.Item
              label="Estiramiento excesivo"
              validateStatus={formik.errors.estiramiento && formik.touched.estiramiento ? "error" : ""}
              help={formik.touched.estiramiento && formik.errors.estiramiento ? formik.errors.estiramiento : ""}
          >
            <Select
                name="estiramiento"
                value={formik.values.estiramiento}
                onChange={(value) => formik.setFieldValue('estiramiento', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
              label="Deterioro general"
              validateStatus={formik.errors.deteriorotejidoco && formik.touched.deteriorotejidoco ? "error" : ""}
              help={formik.touched.deteriorotejidoco && formik.errors.deteriorotejidoco ? formik.errors.deteriorotejidoco : ""}
          >
            <Select
                name="deteriorotejidoco"
                value={formik.values.deteriorotejidoco}
                onChange={(value) => formik.setFieldValue('deteriorotejidoco', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
              label="Corrosión o desgaste por exposición a ácidos o productos químicos"
              validateStatus={formik.errors.corrosion && formik.touched.corrosion ? "error" : ""}
              help={formik.touched.corrosion && formik.errors.corrosion ? formik.errors.corrosion : ""}
          >
            <Select
                name="corrosion"
                value={formik.values.corrosion}
                onChange={(value) => formik.setFieldValue('corrosion', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
              label="Puntos o áreas duras o brillantes indican daño por exposición al calor o a radiación uv."
              validateStatus={formik.errors.areasduras && formik.touched.areasduras ? "error" : ""}
              help={formik.touched.areasduras && formik.errors.areasduras ? formik.errors.areasduras : ""}
          >
            <Select
                name="areasduras"
                value={formik.values.areasduras}
                onChange={(value) => formik.setFieldValue('areasduras', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
              label="Consultar proveedor o fabricante para determinar condición dependiendo de la causa de la decoloración."
              validateStatus={formik.errors.consultaproveedor && formik.touched.consultaproveedor ? "error" : ""}
              help={formik.touched.consultaproveedor && formik.errors.consultaproveedor ? formik.errors.consultaproveedor : ""}
          >
            <Select
                name="consultaproveedor"
                value={formik.values.consultaproveedor}
                onChange={(value) => formik.setFieldValue('consultaproveedor', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Decoloración del material"
              validateStatus={formik.errors.decoloracion && formik.touched.decoloracion ? "error" : ""}
              help={formik.touched.decoloracion && formik.errors.decoloracion ? formik.errors.decoloracion : ""}
          >
            <Select
                name="decoloracion"
                value={formik.values.decoloracion}
                onChange={(value) => formik.setFieldValue('decoloracion', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Quemaduras o fibras derretidas"
              validateStatus={formik.errors.quemaduras && formik.touched.quemaduras ? "error" : ""}
              help={formik.touched.quemaduras && formik.errors.quemaduras ? formik.errors.quemaduras : ""}
          >
            <Select
                name="quemaduras"
                value={formik.values.quemaduras}
                onChange={(value) => formik.setFieldValue('quemaduras', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Presencia de moho"
              validateStatus={formik.errors.moho && formik.touched.moho ? "error" : ""}
              help={formik.touched.moho && formik.errors.moho ? formik.errors.moho : ""}
          >
            <Select
                name="moho"
                value={formik.values.moho}
                onChange={(value) => formik.setFieldValue('moho', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
        <Form.Item
              label="Decoloración del material"
              validateStatus={formik.errors.decoloracion && formik.touched.decoloracion ? "error" : ""}
              help={formik.touched.decoloracion && formik.errors.decoloracion ? formik.errors.decoloracion : ""}
          >
            <Select
                name="decoloracion"
                value={formik.values.decoloracion}
                onChange={(value) => formik.setFieldValue('decoloracion', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Otros"
              validateStatus={formik.errors.otrostejidos && formik.touched.otrostejidos ? "error" : ""}
              help={formik.touched.otrostejidos && formik.errors.otrostejidos ? formik.errors.otrostejidos : ""}
          >
            <Input
                name="otrostejidos"
                value={formik.values.otrostejidos}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
      </Row>
       
      <Row gutter={16}><Col span={8} style={{fontSize:'20px', fontWeight: 'bold', color: 'rgb(204, 48, 43)'}}>Costuras</Col></Row>
      <hr/>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Cortaduras"
              validateStatus={formik.errors.cortaduras && formik.touched.cortaduras ? "error" : ""}
              help={formik.touched.cortaduras && formik.errors.cortaduras ? formik.errors.cortaduras : ""}
          >
            <Select
                name="cortaduras"
                value={formik.values.cortaduras}
                onChange={(value) => formik.setFieldValue('cortaduras', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Deshilachamiento"
              validateStatus={formik.errors.deshilachado && formik.touched.deshilachado ? "error" : ""}
              help={formik.touched.deshilachado && formik.errors.deshilachado ? formik.errors.deshilachado : ""}
          >
            <Select
                name="deshilachado"
                value={formik.values.deshilachado}
                onChange={(value) => formik.setFieldValue('deshilachado', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Hilos faltantes"
              validateStatus={formik.errors.hilos && formik.touched.hilos ? "error" : ""}
              help={formik.touched.hilos && formik.errors.hilos ? formik.errors.hilos : ""}
          >
            <Select
                name="hilos"
                value={formik.values.hilos}
                onChange={(value) => formik.setFieldValue('hilos', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Quemaduras"
              validateStatus={formik.errors.quemadurascosturas && formik.touched.quemadurascosturas ? "error" : ""}
              help={formik.touched.quemadurascosturas && formik.errors.quemadurascosturas ? formik.errors.quemadurascosturas : ""}
          >
            <Select
                name="quemadurascosturas"
                value={formik.values.quemadurascosturas}
                onChange={(value) => formik.setFieldValue('quemadurascosturas', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Exposición a productos químicos"
              validateStatus={formik.errors.exposicionquimicos && formik.touched.exposicionquimicos ? "error" : ""}
              help={formik.touched.exposicionquimicos && formik.errors.exposicionquimicos ? formik.errors.exposicionquimicos : ""}
          >
            <Select
                name="exposicionquimicos"
                value={formik.values.exposicionquimicos}
                onChange={(value) => formik.setFieldValue('exposicionquimicos', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Otros"
              validateStatus={formik.errors.otroscosturas && formik.touched.otroscosturas ? "error" : ""}
              help={formik.touched.otroscosturas && formik.errors.otroscosturas ? formik.errors.otroscosturas : ""}
          >
            <Input
                name="otroscosturas"
                value={formik.values.otroscosturas}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}><Col span={8} style={{fontSize:'20px', fontWeight: 'bold', color: 'rgb(204, 48, 43)'}}>Argollas</Col></Row>
      <hr/>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Con deformaciones o desgaste excesivo"
              validateStatus={formik.errors.deformaciones && formik.touched.deformaciones ? "error" : ""}
              help={formik.touched.deformaciones && formik.errors.deformaciones ? formik.errors.deformaciones : ""}
          >
            <Select
                name="deformaciones"
                value={formik.values.deformaciones}
                onChange={(value) => formik.setFieldValue('deformaciones', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Picaduras, grietas"
              validateStatus={formik.errors.picaduras && formik.touched.picaduras ? "error" : ""}
              help={formik.touched.picaduras && formik.errors.picaduras ? formik.errors.picaduras : ""}
          >
            <Select
                name="picaduras"
                value={formik.values.picaduras}
                onChange={(value) => formik.setFieldValue('picaduras', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Deterioro general"
              validateStatus={formik.errors.deterioroargollas && formik.touched.deterioroargollas ? "error" : ""}
              help={formik.touched.deterioroargollas && formik.errors.deterioroargollas ? formik.errors.deterioroargollas : ""}
          >
            <Select
                name="deterioroargollas"
                value={formik.values.deterioroargollas}
                onChange={(value) => formik.setFieldValue('deterioroargollas', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Defecto de funcionamiento"
              validateStatus={formik.errors.defecto && formik.touched.defecto ? "error" : ""}
              help={formik.touched.defecto && formik.errors.defecto ? formik.errors.defecto : ""}
          >
            <Select
                name="defecto"
                value={formik.values.defecto}
                onChange={(value) => formik.setFieldValue('defecto', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Corrosión"
              validateStatus={formik.errors.corrosionargollas && formik.touched.corrosionargollas ? "error" : ""}
              help={formik.touched.corrosionargollas && formik.errors.corrosionargollas ? formik.errors.corrosionargollas : ""}
          >
            <Select
                name="corrosionargollas"
                value={formik.values.corrosionargollas}
                onChange={(value) => formik.setFieldValue('corrosionargollas', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button type="primary" htmlType="button" onClick={handlePrev}>
              Anterior
            </Button>
            <Button type="primary" htmlType="submit">
              Actualizar
            </Button>
      </div>
    </Form>
    )}
    </>
  );
}

export function InspeccionAlturaFullView(props) {
  const { data } = props;
  const { cancelLoader, loading } = useInspeccionAltura();

  useEffect(() => {
    cancelLoader();
  }, [data]);

  const formik = useFormik({
    initialValues: initialValuesInspeccionAltura(data.Id_formulario, data),
  });

  const columns = [
    {
      title: 'Categoría',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Detalle',
      dataIndex: 'detail',
      key: 'detail',
    },
  ];

  const dataSource = [
    { category: 'Tejidos y Correas', detail: 'Fibras Externas Cortadas, Desgastadas, Desgarradas', value: formik.values.fibrasexternas },
    { category: 'Tejidos y Correas', detail: 'Cortes o Rotura del Tejido o Costuras', value: formik.values.cortes },
    { category: 'Tejidos y Correas', detail: 'Grietas', value: formik.values.grietas },
    { category: 'Tejidos y Correas', detail: 'Estiramiento Excesivo', value: formik.values.estiramiento },
    { category: 'Tejidos y Correas', detail: 'Deterioro General', value: formik.values.deteriorotejidoco },
    { category: 'Tejidos y Correas', detail: 'Corrosión o Desgaste por Exposición a Ácidos o Productos Químicos', value: formik.values.corrosion },
    { category: 'Tejidos y Correas', detail: 'Puntos o Áreas Duras o Brillantes', value: formik.values.areasduras },
    { category: 'Tejidos y Correas', detail: 'Consultar Proveedor o Fabricante', value: formik.values.consultaproveedor },
    { category: 'Tejidos y Correas', detail: 'Decoloración del Material', value: formik.values.decoloracion },
    { category: 'Tejidos y Correas', detail: 'Quemaduras o Fibras Derretidas', value: formik.values.quemaduras },
    { category: 'Tejidos y Correas', detail: 'Presencia de Moho', value: formik.values.moho },
    { category: 'Tejidos y Correas', detail: 'Otros', value: formik.values.otrostejidos },

    { category: 'Costuras', detail: 'Cortaduras', value: formik.values.cortaduras },
    { category: 'Costuras', detail: 'Deshilachamiento', value: formik.values.deshilachado },
    { category: 'Costuras', detail: 'Hilos Faltantes', value: formik.values.hilos },
    { category: 'Costuras', detail: 'Quemaduras', value: formik.values.quemadurascosturas },
    { category: 'Costuras', detail: 'Exposición a Productos Químicos', value: formik.values.exposicionquimicos },
    { category: 'Costuras', detail: 'Otros', value: formik.values.otroscosturas },

    { category: 'Argollas', detail: 'Con Deformaciones o Desgaste Excesivo', value: formik.values.deformaciones },
    { category: 'Argollas', detail: 'Picaduras, Grietas', value: formik.values.picaduras },
    { category: 'Argollas', detail: 'Deterioro General', value: formik.values.deterioroargollas },
    { category: 'Argollas', detail: 'Defecto de Funcionamiento', value: formik.values.defecto },
    { category: 'Argollas', detail: 'Corrosión', value: formik.values.corrosionargollas },
  ];

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh',
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Row style={{ fontSize: '25px' }}>Inspección de Alturas</Row>
      <hr />
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        bordered
        rowKey="detail"
      />
    </>
  );
}

export default InspeccionAlturaFullView;

// export function InspeccionAlturaFullView(props) {

//   const { cancelLoader, loading } = useInspeccionAltura();
//   const { data} = props;

//   useEffect(() => {
//     cancelLoader()
//   }, [data])

//   const formik = useFormik({
//     initialValues: initialValuesInspeccionAltura(data.Id_formulario, data),
//   });

//   return (
//     <>
//     {loading ? (
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           height: "50vh",
//         }}
//       >
//         <CircularProgress />
//       </div>
//     ) : (
//     <Form layout="vertical" style={formStyle}>
//       <Row style={{ fontSize: '25px' }} >Inspección de alturas</Row>
//       <hr/>
//       <Row gutter={16}><Col span={12} style={{fontSize:'20px', fontWeight: 'bold', color: 'rgb(204, 48, 43)'}}>Tejidos y correas</Col></Row>
//       <hr/>
//       <Row gutter={16}>
//         <Col span={10}>
//           <Form.Item label="Fibras externas cortadas, desgastadas, desgarradas" >
//             <Input
//                 name="fibrasexternas"
//                 value={formik.values.fibrasexternas}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Cortes o rotura del tejido o costuras" >
//             <Input
//                 name="cortes"
//                 value={formik.values.cortes}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={6}>
//           <Form.Item label="Grietas" >
//             <Input
//                 name="grietas"
//                 value={formik.values.grietas}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         <Col span={7}>
//           <Form.Item label="Estiramiento excesivo" >
//             <Input
//                 name="estiramiento"
//                 value={formik.values.estiramiento}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={6}>
//           <Form.Item label="Deterioro general" >
//             <Input
//                 name="deteriorotejidoco"
//                 value={formik.values.deteriorotejidoco}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={11}>
//           <Form.Item label="Corrosión o desgaste por exposición a ácidos o productos químicos" >
//             <Input
//                 name="corrosion"
//                 value={formik.values.corrosion}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>
      
//       <Row gutter={24}>
//         <Col span={12}>
//           <Form.Item label="Puntos o áreas duras o brillantes indican daño por exposición al calor o a radiación uv." >
//             <Input
//                 name="areasduras"
//                 value={formik.values.areasduras}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={12}>
//           <Form.Item label="Consultar proveedor o fabricante para determinar condición dependiendo de la causa de la decoloración." >
//             <Input
//                 name="consultaproveedor"
//                 value={formik.values.consultaproveedor}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Decoloración del material" >
//             <Input
//                 name="decoloracion"
//                 value={formik.values.decoloracion}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Quemaduras o fibras derretidas" >
//             <Input
//                 name="quemaduras"
//                 value={formik.values.quemaduras}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Presencia de moho" >
//             <Input
//                 name="moho"
//                 value={formik.values.moho}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Decoloración del material" >
//             <Input
//                 name="decoloracion"
//                 value={formik.values.decoloracion}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Otros" >
//             <Input
//                 name="otrostejidos"
//                 value={formik.values.otrostejidos}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>
       
//       <Row gutter={16}><Col span={8} style={{fontSize:'20px', fontWeight: 'bold', color: 'rgb(204, 48, 43)'}}>Costuras</Col></Row>
//       <hr/>
//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Cortaduras" >
//             <Input
//                 name="cortaduras"
//                 value={formik.values.cortaduras}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Deshilachamiento" >
//             <Input
//                 name="deshilachado"
//                 value={formik.values.deshilachado}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Hilos faltantes" >
//             <Input
//                 name="hilos"
//                 value={formik.values.hilos}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Quemaduras" >
//             <Input
//                 name="quemadurascosturas"
//                 value={formik.values.quemadurascosturas}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Exposición a productos químicos" >
//             <Input
//                 name="exposicionquimicos"
//                 value={formik.values.exposicionquimicos}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Otros" >
//             <Input
//                 name="otroscosturas"
//                 value={formik.values.otroscosturas}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}><Col span={8} style={{fontSize:'20px', fontWeight: 'bold', color: 'rgb(204, 48, 43)'}}>Argollas</Col></Row>
//       <hr/>
//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Con deformaciones o desgaste excesivo" >
//             <Input
//                 name="deformaciones"
//                 value={formik.values.deformaciones}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Picaduras, grietas" >
//             <Input
//                 name="picaduras"
//                 value={formik.values.picaduras}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Deterioro general" >
//             <Input
//                 name="deterioroargollas"
//                 value={formik.values.deterioroargollas}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Defecto de funcionamiento" >
//             <Input
//                 name="defecto"
//                 value={formik.values.defecto}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Corrosión" >
//             <Input
//                 name="corrosionargollas"
//                 value={formik.values.corrosionargollas}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>
//     </Form>
//     )}
//     </>
//   );
// }