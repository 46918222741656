import AddIcon from '@mui/icons-material/Add';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Button,
  Card,
  Container,
  Dialog,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { Dropdown, Menu } from "antd";
import { format } from 'date-fns'
import { useState } from 'react';
import imagen from './1783344.png';
import { CellStyle, ButtonStyle } from "./styles";
import { useAuth } from '../../hooks/useAuth';

export function TablaBase(props) {
  const { Archivos, handleAgregarArchivo, handleEditarArchivo,handleEliminarArchivo } = props;

  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { auth } = useAuth();

  const openVideoModal = (videoUrl) => {
    setSelectedVideoUrl(videoUrl);
    setVideoModalOpen(true);
  };

  const closeVideoModal = () => {
    setSelectedVideoUrl('');
    setVideoModalOpen(false);
  };

  // Paginación
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Búsqueda
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  // Check if Archivos is an array before filtering
const filteredArchivos = Array.isArray(Archivos) ? Archivos.filter(
  (archivo) => archivo.nombre_video.toLowerCase().includes(searchTerm.toLowerCase())
) : [];


  const rowsToShow = filteredArchivos && filteredArchivos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // Popover para editar y eliminar
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [selectedArchivo, setSelectedArchivo] = useState(null);

  const handlePopoverOpen = (event, archivo) => {
    setPopoverAnchorEl(event.currentTarget);
    setSelectedArchivo(archivo);
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
    setSelectedArchivo(null);
  };


  const open = Boolean(popoverAnchorEl);
  const popoverId = open ? 'archivo-popover' : undefined;

  const handleCopyUrl = (url) => {
    const tempInput = document.createElement('input');
    tempInput.value = url;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container>
      <Card sx={{ borderRadius: '12px', padding: '16px', marginBottom: '16px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <TextField
          label="Buscar por nombre de video"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        {auth.me?.universyz_full_modify && [
        <Button
          variant="contained"
          style={ButtonStyle}
          startIcon={<AddIcon />}
          onClick={() => handleAgregarArchivo(selectedArchivo)}
        >
          Agregar
        </Button>
        ]}
      </Card>
      <Card>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
              <TableCell>
                  <Typography variant="subtitle1">ID Curso</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">Nombre del Curso</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">Ruta asociada</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">Grupo</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">Línea</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">Sublínea</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">Tecnología</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">Marca</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">Referencia</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">Módulos</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">Tipo de archivo</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">Fecha de creación</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">Abrir video</Typography>
                </TableCell>
                {auth.me?.universyz_full_modify && <TableCell><Typography style={CellStyle}  variant="subtitle1">Copiar URL</Typography></TableCell>}
                {auth.me?.universyz_full_modify && <TableCell><Typography style={CellStyle}  variant="subtitle1">Acciones</Typography></TableCell>}
              </TableRow>
              {rowsToShow && rowsToShow.map((archivo) => (
                <TableRow key={archivo.id}>
                  <TableCell>{archivo.id_curso}</TableCell>
                  <TableCell>{archivo.nombre_video}</TableCell>
                  <TableCell>{archivo.ruta_asociada}</TableCell>
                  <TableCell>{archivo.grupo}</TableCell>
                  <TableCell>{archivo.linea}</TableCell>
                  <TableCell>{archivo.sublinea}</TableCell>
                  <TableCell>{archivo.it}</TableCell>
                  <TableCell>{archivo.marca}</TableCell>
                  <TableCell>{archivo.referencia}</TableCell>
                  <TableCell>{archivo.modulos}</TableCell>
                  <TableCell>{archivo.tipo_archivo}</TableCell>
                  <TableCell>{archivo.created_at && format(new Date(archivo.created_at), "yyyy-MM-dd HH:mm:ss")}</TableCell>
                  <TableCell>
                    <div>
                      <img
                        src={imagen}
                        alt="Video Thumbnail"
                        style={{ cursor: 'pointer', width: '35px', height: 'auto' }}
                        onClick={() => openVideoModal(archivo.video)}
                      />
                    </div>
                  </TableCell>
                  {auth.me?.universyz_full_modify && [
                  <TableCell>
                  <IconButton onClick={() => handleCopyUrl(archivo.video)}>
                      <FileCopyIcon />
                    </IconButton>
                  </TableCell>
                  ]}
                  {auth.me?.universyz_full_modify && [
                  <TableCell>
                      <Dropdown
                        overlay={
                          <Menu>
                            <Menu.Item
                               onClick={() => handleEditarArchivo(archivo)}
                            >
                              
                              Editar
                            </Menu.Item>
                            <Menu.Item
                               onClick={() => handleEliminarArchivo(archivo)}
                            >
                              
                              Eliminar
                            </Menu.Item>
                          </Menu>
                        }
                        trigger={["click"]}
                      >
                        <IconButton>
                          <MoreVertIcon />
                        </IconButton>
                      </Dropdown>
                    </TableCell>
                  ]}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredArchivos ? filteredArchivos.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      <Dialog open={videoModalOpen} onClose={closeVideoModal}>
        <video src={selectedVideoUrl} controls width="400" height="300" />
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        message="URL copiada con éxito"
      />
    </Container>
  );
}
