import React, { useCallback, useEffect, useState } from "react";
import { useCompetence } from '../../hooks/useCompetence'
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth } from '../../hooks/useAuth';
import { TablaGeneral } from "../../components/comons/TablaGeneral";
import { Modal } from 'antd';
import { CompetenceForm } from '../../components/Marketing/CompetenceForm';

const headers = [
  { label: "ID", field: "id_competidores" },
  { label: "Año", field: "anio" },
  { label: "NIT", field: "nit" },
  { label: "Competidor", field: "competidor" },
  { label: "Grupo", field: "grupo" },

];
const popoverColumns = [
  { label: "ID", field: "id_competidores" },
  { label: "Año", field: "anio" },
  { label: "NIT", field: "nit" },
  { label: "Competidor", field: "competidor" },
  { label: "Grupo", field: "grupo" },
  { label: "Sitio Web", field: "sitio_web" },
  { label: "Año de Mercado", field: "anio_mercado" },
  { label: "Valor FOB", field: "valor_fob" },
  { label: "Valor Ventas", field: "valor_ventas" },
  { label: "Sectores", field: "sectores" },
  { label: "Sucursales Ciudades", field: "sucursales_ciudades" },
];

export function CompetencePage() {
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);
  const [refetch, setRefetch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const { auth } = useAuth();

  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const { loading, Competence, getCompetence, deleteCompetence } = useCompetence();

  useEffect(() => {
    getCompetence();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const handleAdd = useCallback(() => {
    setContentModal(
      <CompetenceForm onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleUpdate = useCallback(
    (data) => {
      setContentModal(
        <CompetenceForm
          onClose={openCloseModal}
          onRefetch={onRefetch}
          Competence={data}
        />
      );
      openCloseModal();
    },
    [openCloseModal, onRefetch]
  );

  const handleDelete = useCallback(
    (data) => {
      deleteCompetence(data.id_competidores);
      onRefetch();
    },
    [deleteCompetence, onRefetch]
  );

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (

        <>
          {(auth.me?.marketing_full_modify) && (
            <TablaGeneral
              Data={Competence}
              columns={headers}
              popoverColumns={popoverColumns}
              handleAgregarData={handleAdd}
              handleEditarData={handleUpdate}
              handleEliminarData={handleDelete}
            />
          )}

          {(auth.me?.marketing_only_view && !auth.me?.marketing_full_modify) && (
            <TablaGeneral
              Data={Competence}
              columns={headers}
              popoverColumns={popoverColumns}
            />
          )}
        </>
      )}
      <Modal
        open={showModal}
        onCancel={openCloseModal}
        footer={null}
        width="70%"
        title="Competencia"
        destroyOnClose={true} // Esto destruirá el contenido del modal cuando se cierre
      >
        {contentModal}
      </Modal>

    </>
  )
}
