import React, { useCallback, useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAuth } from "../hooks/useAuth";
import { AddEditExcel } from "../components/AddEditExcel";
import { TablaBasecomons } from "../components/comons/TablaBasecomons";
import { useExcel } from "../hooks/useExcel";
const headers = [
  { label: "id", field: "id_archivo" },
  { label: "nombre archivo", field: "nombre_archivo" },
  { label: "Numero de registros", field: "n_registros" },
];

export default function UserExcel() {
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);
  const [refetch, setRefetch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [contentModal, setContentModal] = useState(null);
  const { auth } = useAuth();
  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const { loading, Excel, getExcel, deleteExcel } = useExcel();

  useEffect(() => {
    getExcel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const handleAdd = useCallback(() => {
    setContentModal(
      <AddEditExcel onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleUpdate = useCallback(
    (data) => {
      setContentModal(
        <AddEditExcel
          onClose={openCloseModal}
          onRefetch={onRefetch}
          Excel={data}
        />
      );
      openCloseModal();
    },
    [openCloseModal, onRefetch]
  );

  const handleDelete = useCallback(async (selectedRow) => {
    await deleteExcel(selectedRow.id_archivo);
    onRefetch();
  }, [deleteExcel, onRefetch]);


  return (
    <>
      <>
        {(auth.me?.marketing_full_modify) && (
          <TablaBasecomons
            Data={Excel}
            columns={headers}
            handleAgregarData={handleAdd}
            handleEditarData={handleUpdate}
          />
        )}

        {(auth.me?.marketing_only_view && !auth.me?.marketing_full_modify) && (
          <TablaBasecomons
            Data={Excel}
            columns={headers}
          />
        )}
      </>

      <Dialog
        fullScreen={fullScreen}
        open={showModal} onClose={openCloseModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          cargue de archivos
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {contentModal}
          </DialogContentText>
        </DialogContent>

      </Dialog>
    </>
  );
}
