import { BASE_API } from "../utils/constants";
import { makeRequest, makeRequestFormData } from "./Request";


// Función para obtener los datos 
export async function getArchivosApi(token) {
  const url = `${BASE_API}/archivos/archivo`;
  return makeRequest(url, "GET", token);
}

// Función para agregar un nuevo dato
export async function addArchivosApi(data, token) {
  const formData = new FormData();
  Object.keys(data).forEach(key => {
    formData.append(key, data[key]);
  });
  const url = `${BASE_API}/archivos/archivo/`;
  return makeRequestFormData(url, "POST", token, formData);
}



// Función para actualizar los datos 
export async function updateArchivosApi(id, data, token) {
  const formData = new FormData();

  formData.append("grupo", data.grupo);
  formData.append("linea", data.linea);
  formData.append("sublinea", data.sublinea);
  if (data.video !== "") {
    formData.append("video", data.video);
  }
  formData.append("it", data.it);
  formData.append("id_curso", data.id_curso);
  formData.append("marca", data.marca);
  formData.append("nombre_video", data.nombre_video);
  const url = `${BASE_API}/archivos/archivo/${id}/`;
  return makeRequestFormData(url, "PATCH", token, formData);
}
// Función para eliminar 
export async function deleteArchivosApi(id, token) {
  const url = `${BASE_API}/archivos/archivo/${id}/`;
  return makeRequestFormData(url, "DELETE", token);
}