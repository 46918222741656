import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Button, Form, Input, message, Row, Col } from "antd";
import { useComercial } from "../../hooks/useComercial";
import { FormStyle } from "../comons/styles";
export function CommercialForm(props) {
  const { onClose, onRefetch, Comercial } = props;
  const { addComercial, updateComercial } = useComercial();

  const formik = useFormik({
    initialValues: initialValues(Comercial), // Utiliza la función getInitialValues
    validationSchema: Yup.object(Comercial ? updateSchema() : newSchema()),
    onSubmit: async (formValues) => {
      try {
        if (Comercial) await updateComercial(Comercial.cod_id, formValues);
        else await addComercial(formValues);
        onRefetch();
        onClose();
        message.success("Operación exitosa");
      } catch (error) {
        if (error?.message) {
          message.error(error.message);
        } else {
          onClose();
        }
      }
    },
  });

  return (
    <Form layout="vertical" onFinish={formik.handleSubmit} style={FormStyle}>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label="Grupo"
            validateStatus={
              formik.errors.grupo && formik.touched.grupo ? "error" : ""
            }
            help={
              formik.touched.grupo && formik.errors.grupo
                ? formik.errors.grupo
                : ""
            }
          >
            <Input
              name="grupo"
              value={formik.values.grupo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Linea"
            validateStatus={
              formik.errors.linea && formik.touched.linea ? "error" : ""
            }
            help={
              formik.touched.linea && formik.errors.linea
                ? formik.errors.linea
                : ""
            }
          >
            <Input
              name="linea"
              value={formik.values.linea}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Sublinea"
            validateStatus={
              formik.errors.sublinea && formik.touched.sublinea ? "error" : ""
            }
            help={
              formik.touched.sublinea && formik.errors.sublinea
                ? formik.errors.sublinea
                : ""
            }
          >
            <Input
              name="sublinea"
              value={formik.values.sublinea}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        
        <Col span={12}>
          <Form.Item
            label="Marca"
            validateStatus={
              formik.errors.marca && formik.touched.marca ? "error" : ""
            }
            help={
              formik.touched.marca && formik.errors.marca
                ? formik.errors.marca
                : ""
            }
          >
            <Input
              name="marca"
              value={formik.values.marca}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="TI"
            validateStatus={
              formik.errors.ti && formik.touched.ti ? "error" : ""
            }
            help={formik.touched.ti && formik.errors.ti ? formik.errors.ti : ""}
          >
            <Input
              name="ti"
              value={formik.values.ti}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label="Web"
            validateStatus={
              formik.errors.web && formik.touched.web ? "error" : ""
            }
            help={
              formik.touched.web && formik.errors.web ? formik.errors.web : ""
            }
          >
            <Input
              name="web"
              value={formik.values.web}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Soporte de Linea"
            validateStatus={
              formik.errors.soportelinea && formik.touched.soportelinea
                ? "error"
                : ""
            }
            help={
              formik.touched.soportelinea && formik.errors.soportelinea
                ? formik.errors.soportelinea
                : ""
            }
          >
            <Input
              name="soportelinea"
              value={formik.values.soportelinea}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Gerente de Linea"
            validateStatus={
              formik.errors.gerentelinea && formik.touched.gerentelinea
                ? "error"
                : ""
            }
            help={
              formik.touched.gerentelinea && formik.errors.gerentelinea
                ? formik.errors.gerentelinea
                : ""
            }
          >
            <Input
              name="gerentelinea"
              value={formik.values.gerentelinea}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        
      </Row>
      <Row gutter={16}>
      <Col span={8}>
          <Form.Item
            label="Servicio"
            validateStatus={
              formik.errors.servicio && formik.touched.servicio ? "error" : ""
            }
            help={
              formik.touched.servicio && formik.errors.servicio
                ? formik.errors.servicio
                : ""
            }
          >
            <Input
              name="servicio"
              value={formik.values.servicio}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Colombia"
            validateStatus={
              formik.errors.colombia && formik.touched.colombia ? "error" : ""
            }
            help={
              formik.touched.colombia && formik.errors.colombia
                ? formik.errors.colombia
                : ""
            }
          >
            <Input
              name="colombia"
              value={formik.values.colombia}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Peru"
            validateStatus={
              formik.errors.peru && formik.touched.peru ? "error" : ""
            }
            help={
              formik.touched.peru && formik.errors.peru
                ? formik.errors.peru
                : ""
            }
          >
            <Input
              name="peru"
              value={formik.values.peru}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        
      </Row>
      <Row gutter={16}>
  <Col span={8}>
    <Form.Item
      label="Descripción Comercial"
      validateStatus={
        formik.errors.desccomercial && formik.touched.desccomercial ? "error" : ""
      }
      help={
        formik.touched.desccomercial && formik.errors.desccomercial
          ? formik.errors.desccomercial
          : ""
      }
    >
      <Input
        name="desccomercial"
        value={formik.values.desccomercial}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
    </Form.Item>
  </Col>
  <Col span={8}>
    <Form.Item
      label="Máxima Desc. Comercial"
      validateStatus={
        formik.errors.maxdesccomercial && formik.touched.maxdesccomercial ? "error" : ""
      }
      help={
        formik.touched.maxdesccomercial && formik.errors.maxdesccomercial
          ? formik.errors.maxdesccomercial
          : ""
      }
    >
      <Input
        name="maxdesccomercial"
        value={formik.values.maxdesccomercial}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
    </Form.Item>
  </Col>
  <Col span={8}>
    <Form.Item
      label="Representante"
      validateStatus={
        formik.errors.representante && formik.touched.representante ? "error" : ""
      }
      help={
        formik.touched.representante && formik.errors.representante
          ? formik.errors.representante
          : ""
      }
    >
      <Input
        name="representante"
        value={formik.values.representante}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
    </Form.Item>
  </Col>
</Row>
<Row gutter={16}>
  <Col span={8}>
    <Form.Item
      label="Categoría"
      validateStatus={
        formik.errors.categoria && formik.touched.categoria ? "error" : ""
      }
      help={
        formik.touched.categoria && formik.errors.categoria
          ? formik.errors.categoria
          : ""
      }
    >
      <Input
        name="categoria"
        value={formik.values.categoria}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
    </Form.Item>
  </Col>
  <Col span={8}>
    <Form.Item
      label="Categoría de Marca"
      validateStatus={
        formik.errors.categoriamarca && formik.touched.categoriamarca ? "error" : ""
      }
      help={
        formik.touched.categoriamarca && formik.errors.categoriamarca
          ? formik.errors.categoriamarca
          : ""
      }
    >
      <Input
        name="categoriamarca"
        value={formik.values.categoriamarca}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
    </Form.Item>
  </Col>
  <Col span={8}>
    <Form.Item
      label="Meta Anual"
      validateStatus={
        formik.errors.metaanual && formik.touched.metaanual ? "error" : ""
      }
      help={
        formik.touched.metaanual && formik.errors.metaanual
          ? formik.errors.metaanual
          : ""
      }
    >
      <Input
        name="metaanual"
        value={formik.values.metaanual}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
    </Form.Item>
  </Col>
</Row>
<Row gutter={16}>
  <Col span={8}>
    <Form.Item
      label="Meta Mes"
      validateStatus={
        formik.errors.metames && formik.touched.metames ? "error" : ""
      }
      help={
        formik.touched.metames && formik.errors.metames
          ? formik.errors.metames
          : ""
      }
    >
      <Input
        name="metames"
        value={formik.values.metames}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
    </Form.Item>
  </Col>
  <Col span={8}>
    <Form.Item
      label="Meta Anual SYZ"
      validateStatus={
        formik.errors.metaanualsyz && formik.touched.metaanualsyz ? "error" : ""
      }
      help={
        formik.touched.metaanualsyz && formik.errors.metaanualsyz
          ? formik.errors.metaanualsyz
          : ""
      }
    >
      <Input
        name="metaanualsyz"
        value={formik.values.metaanualsyz}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
    </Form.Item>
  </Col>
  <Col span={8}>
    <Form.Item
      label="Lista de Precios"
      validateStatus={
        formik.errors.listaprecios && formik.touched.listaprecios ? "error" : ""
      }
      help={
        formik.touched.listaprecios && formik.errors.listaprecios
          ? formik.errors.listaprecios
          : ""
      }
    >
      <Input
        name="listaprecios"
        value={formik.values.listaprecios}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
    </Form.Item>
  </Col>
</Row>

<Row gutter={16}>
<Col span={6}>
    <Form.Item
      label="Vigencia Hasta"
      validateStatus={
        formik.errors.vigenciahasta && formik.touched.vigenciahasta ? "error" : ""
      }
      help={
        formik.touched.vigenciahasta && formik.errors.vigenciahasta
          ? formik.errors.vigenciahasta
          : ""
      }
    >
      <Input
        name="vigenciahasta"
        value={formik.values.vigenciahasta}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
    </Form.Item>
  </Col>
  
      <Col span={6}>
        <Form.Item
          label="Descuento"
          validateStatus={formik.errors.descuento && formik.touched.descuento ? "error" : ""}
          help={formik.touched.descuento && formik.errors.descuento ? formik.errors.descuento : ""}
        >
          <Input
            name="descuento"
            value={formik.values.descuento}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          label="Pedido Mínimo"
          validateStatus={formik.errors.pedidominimo && formik.touched.pedidominimo ? "error" : ""}
          help={formik.touched.pedidominimo && formik.errors.pedidominimo ? formik.errors.pedidominimo : ""}
        >
          <Input
            name="pedidominimo"
            value={formik.values.pedidominimo}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          label="Plazo de Pago"
          validateStatus={formik.errors.plazopago && formik.touched.plazopago ? "error" : ""}
          help={formik.touched.plazopago && formik.errors.plazopago ? formik.errors.plazopago : ""}
        >
          <Input
            name="plazopago"
            value={formik.values.plazopago}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col span={6}>
        <Form.Item
          label="Lugar de Entrega"
          validateStatus={formik.errors.lugarentrega && formik.touched.lugarentrega ? "error" : ""}
          help={formik.touched.lugarentrega && formik.errors.lugarentrega ? formik.errors.lugarentrega : ""}
        >
          <Input
            name="lugarentrega"
            value={formik.values.lugarentrega}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          label="Contacto Comercial"
          validateStatus={formik.errors.contactocomercial && formik.touched.contactocomercial ? "error" : ""}
          help={formik.touched.contactocomercial && formik.errors.contactocomercial ? formik.errors.contactocomercial : ""}
        >
          <Input
            name="contactocomercial"
            value={formik.values.contactocomercial}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          label="Contacto Técnico"
          validateStatus={formik.errors.contactotecnico && formik.touched.contactotecnico ? "error" : ""}
          help={formik.touched.contactotecnico && formik.errors.contactotecnico ? formik.errors.contactotecnico : ""}
        >
          <Input
            name="contactotecnico"
            value={formik.values.contactotecnico}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          label="Contacto Compras"
          validateStatus={formik.errors.contactocompras && formik.touched.contactocompras ? "error" : ""}
          help={formik.touched.contactocompras && formik.errors.contactocompras ? formik.errors.contactocompras : ""}
        >
          <Input
            name="contactocompras"
            value={formik.values.contactocompras}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      
      <Col span={8}>
        <Form.Item
          label="Link Portal"
          validateStatus={formik.errors.linkportal && formik.touched.linkportal ? "error" : ""}
          help={formik.touched.linkportal && formik.errors.linkportal ? formik.errors.linkportal : ""}
        >
          <Input
            name="linkportal"
            value={formik.values.linkportal}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label="Usuario Portal"
          validateStatus={formik.errors.usuarioportal && formik.touched.usuarioportal ? "error" : ""}
          help={formik.touched.usuarioportal && formik.errors.usuarioportal ? formik.errors.usuarioportal : ""}
        >
          <Input
            name="usuarioportal"
            value={formik.values.usuarioportal}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label="Clave Portal"
          validateStatus={formik.errors.claveportal && formik.touched.claveportal ? "error" : ""}
          help={formik.touched.claveportal && formik.errors.claveportal ? formik.errors.claveportal : ""}
        >
          <Input
            name="claveportal"
            value={formik.values.claveportal}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      
      <Col span={6}>
        <Form.Item
          label="Software de Dimensionamiento"
          validateStatus={formik.errors.swdimensionamiento && formik.touched.swdimensionamiento ? "error" : ""}
          help={formik.touched.swdimensionamiento && formik.errors.swdimensionamiento ? formik.errors.swdimensionamiento : ""}
        >
          <Input
            name="swdimensionamiento"
            value={formik.values.swdimensionamiento}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          label="URL Selector"
          validateStatus={formik.errors.urlselector && formik.touched.urlselector ? "error" : ""}
          help={formik.touched.urlselector && formik.errors.urlselector ? formik.errors.urlselector : ""}
        >
          <Input
            name="urlselector"
            value={formik.values.urlselector}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          label="Usuario Selector"
          validateStatus={formik.errors.usuarioselector && formik.touched.usuarioselector ? "error" : ""}
          help={formik.touched.usuarioselector && formik.errors.usuarioselector ? formik.errors.usuarioselector : ""}
        >
          <Input
            name="usuarioselector"
            value={formik.values.usuarioselector}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          label="Clave Selector"
          validateStatus={formik.errors.claveselector && formik.touched.claveselector ? "error" : ""}
          help={formik.touched.claveselector && formik.errors.claveselector ? formik.errors.claveselector : ""}
        >
          <Input
            name="claveselector"
            value={formik.values.claveselector}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      
      <Col span={24}>
        <Form.Item
          label="Recomendaciones"
          validateStatus={formik.errors.recomendaciones && formik.touched.recomendaciones ? "error" : ""}
          help={formik.touched.recomendaciones && formik.errors.recomendaciones ? formik.errors.recomendaciones : ""}
        >
          <Input
            name="recomendaciones"
            value={formik.values.recomendaciones}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
      </Col>
    </Row>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          {Comercial ? "Actualizar" : "Crear"}
        </Button>
      </Form.Item>
    </Form>
  );
}

// Función para inicializar los valores del formulario
function initialValues(data) {
  return {
    grupo: data?.grupo || "",
    linea: data?.linea || "",
    sublinea: data?.sublinea || "",
    marca: data?.marca || "",
    ti: data?.ti || "",
    web: data?.web || "",
    soportelinea: data?.soportelinea || "",
    gerentelinea: data?.gerentelinea || "",
    servicio: data?.servicio || "",
    colombia: data?.colombia || "",
    peru: data?.peru || "",
    desccomercial: data?.desccomercial || "",
    maxdesccomercial: data?.maxdesccomercial || "",
    representante: data?.representante || "",
    categoria: data?.categoria || "",
    categoriamarca: data?.categoriamarca || "",
    metaanual: data?.metaanual || "",
    metames: data?.metames || "",
    metaanualsyz: data?.metaanualsyz || "",
    listaprecios: data?.listaprecios || "",
    vigenciahasta: data?.vigenciahasta || "",
    descuento: data?.descuento || "",
    pedidominimo: data?.pedidominimo || "",
    plazopago: data?.plazopago || "",
    lugarentrega: data?.lugarentrega || "",
    contactocomercial: data?.contactocomercial || "",
    contactotecnico: data?.contactotecnico || "",
    contactocompras: data?.contactocompras || "",
    linkportal: data?.linkportal || "",
    usuarioportal: data?.usuarioportal || "",
    claveportal: data?.claveportal || "",
    swdimensionamiento: data?.swdimensionamiento || "",
    urlselector: data?.urlselector || "",
    usuarioselector: data?.usuarioselector || "",
    claveselector: data?.claveselector || "",
    recomendaciones: data?.recomendaciones || "",
  };
}

// Función para definir el esquema de validación del formulario
function newSchema() {
  return {
    grupo: Yup.string().required("El campo de grupo es requerido"),
    linea: Yup.string().required("El campo línea es requerido"),
    sublinea: Yup.string().required("El campo sublínea es requerido"),
    marca: Yup.string().required("El campo marca es requerido"),
    ti: Yup.string().required("El campo ti es requerido"),
    web: Yup.string().required("El campo web es requerido"),
    soportelinea: Yup.string().required(
      "El campo soporte de línea es requerido"
    ),
    gerentelinea: Yup.string().required(
      "El campo gerente de línea es requerido"
    ),
    servicio: Yup.string().required("El campo servicio es requerido"),
    colombia: Yup.string().required("El campo Colombia es requerido"),
    peru: Yup.string().required("El campo Perú es requerido"),
    desccomercial: Yup.string().required(
      "El campo descripción comercial es requerido"
    ),
    maxdesccomercial: Yup.string().required(
      "El campo máxima descripción comercial es requerido"
    ),
    representante: Yup.string().required("El campo representante es requerido"),
    categoria: Yup.string().required("El campo categoría es requerido"),
    categoriamarca: Yup.string().required(
      "El campo categoría marca es requerido"
    ),
    metaanual: Yup.string().required("El campo meta anual es requerido"),
    metames: Yup.string().required("El campo meta mensual es requerido"),
    metaanualsyz: Yup.string().required("El campo meta anual SYZ es requerido"),
    listaprecios: Yup.string().required(
      "El campo lista de precios es requerido"
    ),
    vigenciahasta: Yup.string().required(
      "El campo vigencia hasta es requerido"
    ),
    descuento: Yup.string().required("El campo descuento es requerido"),
    pedidominimo: Yup.string().required("El campo pedido mínimo es requerido"),
    plazopago: Yup.string().required("El campo plazo de pago es requerido"),
    lugarentrega: Yup.string().required(
      "El campo lugar de entrega es requerido"
    ),
    contactocomercial: Yup.string().required(
      "El campo contacto comercial es requerido"
    ),
    contactotecnico: Yup.string().required(
      "El campo contacto técnico es requerido"
    ),
    contactocompras: Yup.string().required(
      "El campo contacto de compras es requerido"
    ),
    linkportal: Yup.string().required("El campo enlace al portal es requerido"),
    usuarioportal: Yup.string().required(
      "El campo usuario del portal es requerido"
    ),
    claveportal: Yup.string().required(
      "El campo clave del portal es requerido"
    ),
    swdimensionamiento: Yup.string().required(
      "El campo sw dimensionamiento es requerido"
    ),
    urlselector: Yup.string().required(
      "El campo URL del selector es requerido"
    ),
    usuarioselector: Yup.string().required(
      "El campo usuario del selector es requerido"
    ),
    claveselector: Yup.string().required(
      "El campo clave del selector es requerido"
    ),
    recomendaciones: Yup.string().required(
      "El campo recomendaciones es requerido"
    ),
  };
}

function updateSchema() {
  return {
    grupo: Yup.string().required("El campo de grupo es requerido"),
    linea: Yup.string().required("El campo línea es requerido"),
    sublinea: Yup.string().required("El campo sublínea es requerido"),
    marca: Yup.string().required("El campo marca es requerido"),
    ti: Yup.string().required("El campo ti es requerido"),
    web: Yup.string().required("El campo web es requerido"),
    soportelinea: Yup.string().required(
      "El campo soporte de línea es requerido"
    ),
    gerentelinea: Yup.string().required(
      "El campo gerente de línea es requerido"
    ),
    servicio: Yup.string().required("El campo servicio es requerido"),
    colombia: Yup.string().required("El campo Colombia es requerido"),
    peru: Yup.string().required("El campo Perú es requerido"),
    desccomercial: Yup.string().required(
      "El campo descripción comercial es requerido"
    ),
    maxdesccomercial: Yup.string().required(
      "El campo máxima descripción comercial es requerido"
    ),
    representante: Yup.string().required("El campo representante es requerido"),
    categoria: Yup.string().required("El campo categoría es requerido"),
    categoriamarca: Yup.string().required(
      "El campo categoría marca es requerido"
    ),
    metaanual: Yup.string().required("El campo meta anual es requerido"),
    metames: Yup.string().required("El campo meta mensual es requerido"),
    metaanualsyz: Yup.string().required("El campo meta anual SYZ es requerido"),
    listaprecios: Yup.string().required(
      "El campo lista de precios es requerido"
    ),
    vigenciahasta: Yup.string().required(
      "El campo vigencia hasta es requerido"
    ),
    descuento: Yup.string().required("El campo descuento es requerido"),
    pedidominimo: Yup.string().required("El campo pedido mínimo es requerido"),
    plazopago: Yup.string().required("El campo plazo de pago es requerido"),
    lugarentrega: Yup.string().required(
      "El campo lugar de entrega es requerido"
    ),
    contactocomercial: Yup.string().required(
      "El campo contacto comercial es requerido"
    ),
    contactotecnico: Yup.string().required(
      "El campo contacto técnico es requerido"
    ),
    contactocompras: Yup.string().required(
      "El campo contacto de compras es requerido"
    ),
    linkportal: Yup.string().required("El campo enlace al portal es requerido"),
    usuarioportal: Yup.string().required(
      "El campo usuario del portal es requerido"
    ),
    claveportal: Yup.string().required(
      "El campo clave del portal es requerido"
    ),
    swdimensionamiento: Yup.string().required(
      "El campo sw dimensionamiento es requerido"
    ),
    urlselector: Yup.string().required(
      "El campo URL del selector es requerido"
    ),
    usuarioselector: Yup.string().required(
      "El campo usuario del selector es requerido"
    ),
    claveselector: Yup.string().required(
      "El campo clave del selector es requerido"
    ),
    recomendaciones: Yup.string().required(
      "El campo recomendaciones es requerido"
    ),
  };
}
