import { Button, Col, Form, Input, Row, message, DatePicker, Select, Upload } from "antd";
import dayjs from 'dayjs';
import { UploadOutlined } from '@ant-design/icons';
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { FormStyle, ButtonStyle, ButtonStyleSecond } from "../comons/styles";
import { useAuth } from "../../hooks/useAuth";
import { sendRequest } from "../../Api/Request";
import { getDocumentUrl } from "../../Api/documentManagement/RequestsDocument";

const { Option } = Select;

export function FormDocument(props) {
  const { onClose, onRefetch, documents, trds } = props;
  const [fileList, setFileList] = useState([]);
  const { auth } = useAuth();

  const formik = useFormik({
    initialValues: initialValues(documents),
    validationSchema: documents ? updateSchema() : newSchema(),
    onSubmit: async (formValues) => {
      try {
        const formData = new FormData();
        for (const [key, value] of Object.entries(formValues)) {
          if (key !== 'archivo') {
            formData.append(key, value);
          }
        }

        if (fileList.length > 0) {
          formData.append('archivo', fileList[0]?.originFileObj);
        }

        const url = getDocumentUrl(documents ? documents.id_documento : null);
        const method = documents ? 'PUT' : 'POST';
        const [result] = await sendRequest(url, method, auth.token, formData);
        console.log(result);

        message.success('Documento guardado exitosamente');
        onRefetch();
        onClose();
      } catch (error) {
        message.error(`Error al guardar el documento: ${error.message}`);
      }
    },
  });

  const handleFileChange = ({ file, fileList }) => {
    if (file.type !== 'application/pdf') {
      message.error('Solo se permiten archivos PDF');
      return;
    }
    setFileList(fileList.slice(-1));
  };

  return (
    <Form
      layout="vertical"
      onFinish={formik.handleSubmit}
      style={FormStyle}
      encType="multipart/form-data"
    >

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Nombre del Documento"
            validateStatus={formik.errors.nom_documento && formik.touched.nom_documento ? "error" : ""}
            help={formik.touched.nom_documento && formik.errors.nom_documento ? formik.errors.nom_documento : ""}
          >
            <Input
              name="nom_documento"
              value={formik.values.nom_documento}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Acta del Comité de Gestión de TIC..."
            />
          </Form.Item>
        </Col>
        <Col span={12}>          <Form.Item
          label="Número del Documento"
          validateStatus={formik.errors.num_documento && formik.touched.num_documento ? "error" : ""}
          help={formik.touched.num_documento && formik.errors.num_documento ? formik.errors.num_documento : ""}
        >
          <Input
            name="num_documento"
            value={formik.values.num_documento}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="12345..."
          />
        </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Emisor"
            validateStatus={formik.errors.emisor && formik.touched.emisor ? "error" : ""}
            help={formik.touched.emisor && formik.errors.emisor ? formik.errors.emisor : ""}
          >
            <Input
              name="emisor"
              value={formik.values.emisor}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Juan Lopez..."
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Fecha del Documento"
            validateStatus={formik.errors.fecha_documento && formik.touched.fecha_documento ? "error" : ""}
            help={formik.touched.fecha_documento && formik.errors.fecha_documento ? formik.errors.fecha_documento : ""}
          >
            <DatePicker
              style={{ width: '100%' }}
              name="fecha_documento"
              value={formik.values.fecha_documento ? dayjs(formik.values.fecha_documento) : null}
              onChange={(data, dateString) => formik.setFieldValue('fecha_documento', dateString)}
              onBlur={() => formik.setFieldTouched('fecha_documento')}
              placeholder="Seleccione una fecha..."
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Observación"
            validateStatus={formik.errors.observacion && formik.touched.observacion ? "error" : ""}
            help={formik.touched.observacion && formik.errors.observacion ? formik.errors.observacion : ""}
          >
            <Input.TextArea
              name="observacion"
              value={formik.values.observacion}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Digite una observación..."
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Descripción"
            validateStatus={formik.errors.descripcion && formik.touched.descripcion ? "error" : ""}
            help={formik.touched.descripcion && formik.errors.descripcion ? formik.errors.descripcion : ""}
          >
            <Input.TextArea
              name="descripcion"
              value={formik.values.descripcion}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Digite una descripción..."
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Retención Documental"
            validateStatus={formik.errors.id_trd && formik.touched.id_trd ? "error" : ""}
            help={formik.touched.id_trd && formik.errors.id_trd ? formik.errors.id_trd : ""}
          >
            <Select
              name="trd"
              value={formik.values.id_trd}
              onChange={(value) => formik.setFieldValue('id_trd', value)}
              onBlur={formik.handleBlur}
              placeholder="Seleccione una retención documental"
              showSearch
              filterOption={(input, option) =>
                option?.children?.toLowerCase().includes(input.toLowerCase())
              }
            >
              {(trds || []).map((trd) => (
                <Option key={trd.id_trd} value={trd.id_trd}>
                  {trd.categoria || 'Sin categoría'}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Solo archivos con extensión .pdf"
            validateStatus={formik.errors.archivo && formik.touched.archivo ? "error" : ""}
            help={formik.touched.archivo && formik.errors.archivo ? formik.errors.archivo : ""}
          >
            <Upload
              fileList={fileList}
              beforeUpload={() => false}
              onChange={(handleFileChange)}
              accept=".pdf"
              onRemove={() => setFileList([])}
            >
              <Button icon={<UploadOutlined />} style={ButtonStyle} >
                {documents ? "Actualizar Archivo" : "Subir Archivo"}
              </Button>
            </Upload>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={ButtonStyleSecond}>
              {documents ? "Actualizar" : "Crear"}
            </Button>
          </Form.Item>
        </Col>
      </Row>

    </Form>
  );
}

function initialValues(data) {
  return {
    id_trd: data?.id_trd || null,
    nom_documento: data?.nom_documento || "",
    num_documento: data?.num_documento || "",
    emisor: data?.emisor || "",
    fecha_documento: data?.fecha_documento || null,
    descripcion: data?.descripcion || "",
    observacion: data?.observacion || "",
    archivo: data?.archivo || "",
    vigente: data?.vigente || false,
  };
}

function newSchema() {
  return Yup.object({
    id_trd: Yup.string().required("El campo de TRD es requerido"),
    nom_documento: Yup.string().required("El campo de nombre del documento es requerido"),
    num_documento: Yup.string().required("El campo de número del documento es requerido"),
    emisor: Yup.string().required("El campo de emisor es requerido"),
    fecha_documento: Yup.date().required("El campo de fecha del documento es requerido"),
    descripcion: Yup.string().required("El campo de descripción es requerido"),
    observacion: Yup.string().required("El campo de observación es requerido"),
    // archivo: Yup.string().required("Por favor cargue un achivo es requerido"),
  });
}

function updateSchema() {
  return Yup.object({
    id_trd: Yup.string().required("El campo de TRD es requerido"),
    nom_documento: Yup.string().required("El campo de nombre del documento es requerido"),
    num_documento: Yup.string().required("El campo de número del documento es requerido"),
    emisor: Yup.string().required("El campo de emisor es requerido"),
    fecha_documento: Yup.date().required("El campo de fecha del documento es requerido"),
    descripcion: Yup.string().required("El campo de descripción es requerido"),
    observacion: Yup.string().required("El campo de observación es requerido"),
  });
}