import React from 'react';
import { Modal, Typography, List } from 'antd';
import { CellStyle } from './styles';

const CustomPopover = ({ id, open, onClose, Columns, selectedData }) => {
  return (
    <Modal
      title="Contenido"
      open={open}
      onCancel={onClose}
      width="40%"
      footer={null}
      centered
    >
      {Columns && selectedData ? (
        <List
          dataSource={Columns}
          renderItem={(header) => (
            <List.Item key={header.field} style={{ padding: '8px 16px' }}>
              <Typography.Text style={ CellStyle }>
                {header.label}:
              </Typography.Text>
              <Typography.Text style={ CellStyle }>
                {selectedData[header.field]}
              </Typography.Text>
            </List.Item>
          )}
        />
      ) : (
        <Typography>No data available.</Typography>
      )}
    </Modal>
  );
};

export default CustomPopover;
