import { alignProperty } from "@mui/material/styles/cssUtils";
import styled from "styled-components";

export const ContainerPopover = styled.div`
  width: 400px;
`;
export const ContainerContent = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;
export const ContainerFlex = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const TextBold = {
  fontSize: '12px',
  fontWeight: '700',
  margin: '0',
};

export const SpanNormal = {
  fontSize: '12px',
  fontWeight: '400',
};

export const TextCrudo = {
  color: '#100db1',
  fontSize: '14px',
  margin: '0',
};

export const Circle = {
  width: '7px',
  height: '7px',
  borderRadius: '50%',
};

export const CircleCerrado = {
  ...Circle,
  backgroundColor: '#000',
};

export const CirclePendiente = {
  ...Circle,
  backgroundColor: '#aaa',
};

export const CircleAbierto = {
  ...Circle,
  backgroundColor: '#f00',
};

export const CellStyle = {
  fontSize: '12px',
  fontFamily: 'Noto, sans-serif',
  textAlign: 'center',
};

export const CellStyleComisiones = {
  fontSize: '10.5px',
  fontFamily: 'Noto, sans-serif',
  textAlign: 'center',
};

export const CellStyleSig = {
  fontSize: '12px',
  fontFamily: 'Noto, sans-serif',
  width: '230px',
};

export const FormStyle = {
  width: '100%',
  margin: '0 auto',
  backgroundColor: '#FFFFFF',
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0 2px 8px rgba(0.1, 0.1, 0.1, 0.1)',
};

export const ButtonStyle = {
  backgroundColor: '#CC302B',
  color: '#FFFFFF',
  borderRadius: '7px',
  padding: '5px 10px',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.5)',
  border: 'none',
  cursor: 'pointer',
  transition: 'background-color 0.5s ease',
  width: '200px',
  height: '35px',
  textAlign: 'center',
  '&:hover': {
    backgroundColor: '#BEBEBE',
  }
};

export const ButtonStyleSecond = {
  backgroundColor: '#444444',
  color: '#FFFFFF',
  borderRadius: '7px',
  padding: '5px 10px',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.5)',
  border: 'none',
  cursor: 'pointer',
  transition: 'background-color 5s ease',
  width: '200px',
  height: '35px',
  textAlign: 'center',
  display: 'inline-block',
  '&:hover': {
    backgroundColor: '#BEBEBE',
  }
};

export const IconStyle = {
  fontSize: '20px',
  marginLeft: '10px',
};

export const InputStyle = {
  borderRadius: '6px',
  padding: '10px',
  border: '1px solid #ccc',
  transition: 'border-color 0.3s ease',
  '&:focus': {
    borderColor: '#1976d2',
  },
};

// import styled from "styled-components";

// export const ContainerPopover = styled.div`
//   width: 400px;
// `;

// export const ContainerContent = styled.div`
//   display: flex;
//   justify-content: space-between;
//   gap: 8px;
// `;

// export const ContainerFlex = styled.div`
//   display: flex;
//   gap: 8px;
//   align-items: center;
// `;

// export const TextBold = styled.p`
//   font-size: 12px;
//   font-weight: 700;
//   margin: 0;
// `;

// export const SpanNormal = styled.span`
//   font-size: 12px;
//   font-weight: 400;
// `;

// export const TextCrudo = styled.p`
//   color: #100db1;
//   font-size: 14px;
//   margin: 0;
// `;

// export const Circle = styled.div`
//   width: 7px;
//   height: 7px;
//   border-radius: 50%;
// `;

// export const CircleCerrado = styled(Circle)`
//   background-color: #000;
// `;

// export const CirclePendiente = styled(Circle)`
//   background-color: #aaa;
// `;

// export const CircleAbierto = styled(Circle)`
//   background-color: #f00;
// `;

// export const CellStyle = styled.div`
//   font-size: 12px;
//   font-family: 'Noto', sans-serif;
//   text-align: center;
// `;

// export const CellStyleComisiones = styled.div`
//   font-size: 10.5px;
//   font-family: 'Noto', sans-serif;
//   text-align: center;
// `;

// export const CellStyleSig = styled.div`
//   font-size: 12px;
//   font-family: 'Noto', sans-serif;
//   width: 230px;
// `;

// export const FormStyle = styled.form`
//   width: 100%;
//   margin: 0 auto;
//   background-color: #f9f9f9;
//   padding: 20px;
//   border-radius: 8px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
// `;

// export const ButtonStyle = styled.button`
//   background-color: #CC302B;
//   color: white;
//   border-radius: 8px;
//   padding: 5px 10px;
//   box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.7);
//   border: none;
//   cursor: pointer;
//   transition: background-color 0.3s ease;

//   &:hover {
//     background-color: #e7a19e;
//   }
// `;

// export const ButtonStyleSecond = styled.button`
//   background-color: #1976d2;
//   color: #fff;
//   margin-left: 10px;
//   border-radius: 8px;
//   padding: 5px 10px;
//   box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.7);
//   border: none;
//   cursor: pointer;
//   transition: background-color 1s ease;

//   &:hover {
//     background-color: #99c4f5;
//   }
// `;

// export const IconStyle = styled.i`
//   font-size: 20px;
//   margin-left: 10px;
// `;

// export const InputStyle = styled.input`
//   border-radius: 6px;
//   padding: 10px;
//   border: 1px solid #ccc;
//   transition: border-color 0.3s ease;

//   &:focus {
//     border-color: #1976d2;
//   }
// `;