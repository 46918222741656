import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Col, Form, Input, Row, Button, Select, DatePicker, message, Cascader, Table} from "antd";
import moment from "moment";
import { useFormik } from "formik";
import { useFormularioSig } from "../../../../hooks/administrativa/formularios-sig/useFormularioSig";
import { initialValuesDatosActividad } from "../components/initialValues";
import { datosActividadSchema } from "../components/validations";
import { useAuth } from "../../../../hooks/useAuth";
import { format } from 'date-fns';
import './styles.css';


export function DatosActividadFormAdd(props) {

    const { onClose, onRefetch} = props;
    const { addFormularioSig, getFormularioSig, FormularioSig } = useFormularioSig();
    const { Option } = Select;
    const { auth } = useAuth();
    const creador_name = auth.me?.full_name;
    const Id_creador = auth.me?.id_user;

    useEffect(() => {
        getFormularioSig()
    }, []);

    const formik = useFormik({
        initialValues: initialValuesDatosActividad(FormularioSig, Id_creador, creador_name),
        //validationSchema: datosActividadSchema,
        onSubmit: async (formValues) => {
            try {
                try {
                    await addFormularioSig(formValues);
                    onRefetch();
                    onClose();
                    message.success("Creación exitosa");
                } catch (error) {
                    message.error("Error en la operación");
                }
            } catch (error) {
                message.error("Error en el sistema: ", error);
            }
        },     
    });

    const disabledDate = (current) => {

        const startDate = formik.values.fecha_elaboracion;

        //Los valores que definen los dias Sabados es 6 y los domingos es 0
        //

        if (!startDate) {
          //return current && (current.day() === 6 || current.day() === 0); // Deshabilitar sábados y domingos si no hay fecha de inicio
          return current && current.day() === 0; // Deshabilitar sábados y domingos si no hay fecha de inicio
        }

        const minDate = moment(startDate).add(1, "days");
    
        let countBusinessDays = 0;
        let tempDate = moment(startDate);
    
        // Iterar hasta encontrar 6 días hábiles (lunes a viernes)
        while (countBusinessDays < 8) {
          if (tempDate.day() !== 8 && tempDate.day() !== 0) {
            countBusinessDays++; // Contar el día hábil encontrado
          }
    
          // Avanzar al siguiente día
          tempDate.add(1, "days");
        }
    
        // endDate será el último día hábil encontrado
        const endDate = tempDate;
    
        return (
          // Deshabilitar sábados y domingos o fechas fuera del rango permitido
          //(current && (current.day() === 6 || current.day() === 0)) || // Sábado (6) o domingo (0)
          (current && current.day() === 0) || // domingo (0)
          (current && (current.isBefore(minDate) || current.isAfter(endDate))) // Fuera del rango de fecha permitido
        );
      };

    return(
    <>
        <Form onFinish={formik.handleSubmit} layout="vertical">
            <Row gutter={16}>
                <Col span={6}>
                    <Form.Item 
                        label="Elija un tipo de formulario" 
                    >
                        <Select
                            name="tipoformulario"
                            value={formik.values.tipoformulario}
                            onChange={(value) => formik.setFieldValue('tipoformulario', value)} // Actualiza el valor en formik
                            onBlur={formik.handleBlur}
                            placeholder="Seleccione un tipo de formulario"
                            >
                            <Option value="ATS">ATS</Option>
                            <Option value="PT Alturas">PT Alturas</Option>
                            <Option value="PT Confinado">PT Confinado</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            {formik.values.tipoformulario !== "" && (
            <>
            <Row gutter={16}>
                <Col span={8}>
                    <Form.Item
                        label="Fecha Elaboración"
                        validateStatus={formik.errors.fecha_elaboracion && formik.touched.fecha_elaboracion
                        ? "error"
                        : ""}
                        help={formik.touched.fecha_elaboracion && formik.errors.fecha_elaboracion
                        ? formik.errors.fecha_elaboracion
                        : ""}
                    >
                            <DatePicker
                                showTime
                                name="fecha_elaboracion"
                                style={{width: '100%'}}
                                value={(formik.values.fecha_elaboracion ? moment(formik.values.fecha_elaboracion) : null)}
                                onChange={(date, dateString) => formik.setFieldValue('fecha_elaboracion', dateString)}
                                onBlur={() => formik.setFieldTouched('fecha_elaboracion')} 
                            />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Fecha de Inicio"
                        validateStatus={formik.errors.fecha_inicio && formik.touched.fecha_inicio
                        ? "error"
                        : ""}
                        help={formik.touched.fecha_inicio && formik.errors.fecha_inicio
                        ? formik.errors.fecha_inicio
                        : ""}
                    >
                        <DatePicker
                            showTime
                            name="fecha_inicio"
                            style={{ width: '100%' }}
                            value={formik.values.fecha_inicio ? moment(formik.values.fecha_inicio) : null}
                            onChange={(date, dateString) => formik.setFieldValue('fecha_inicio', dateString)}
                            onBlur={() => formik.setFieldTouched('fecha_inicio')} 
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Fecha Final"
                        validateStatus={formik.errors.fecha_final && formik.touched.fecha_final
                        ? "error"
                        : ""}
                        help={formik.touched.fecha_final && formik.errors.fecha_final
                        ? formik.errors.fecha_final
                        : ""}
                    >
                        <DatePicker
                            showTime
                            name="fecha_final"
                            style={{ width: '100%' }}
                            value={formik.values.fecha_final ? moment(formik.values.fecha_final) : null}
                            onChange={(date, dateString) => formik.setFieldValue('fecha_final', dateString)}
                            onBlur={() => formik.setFieldTouched('fecha_final')} 
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>   
            <Col span={7}>
                <Form.Item
                    label="No. Orden Servicio / Proyecto"
                    validateStatus={formik.errors.num_orden && formik.touched.num_orden ? "error" : ""}
                    help={formik.touched.num_orden && formik.errors.num_orden
                    ? formik.errors.num_orden
                    : ""}
                >
                    <Input
                    name="num_orden"
                    value={formik.values.num_orden}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} />
                </Form.Item>
            </Col>
            <Col span={5}>
                <Form.Item
                    label="Empresa"
                    validateStatus={formik.errors.empresa && formik.touched.empresa ? "error" : ""}
                    help={formik.touched.empresa && formik.errors.empresa
                    ? formik.errors.empresa
                    : ""}
                >
                    
                    <Input
                    name="empresa"
                    value={formik.values.empresa}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} />
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item
                    label="Lugar de trabajo"
                    validateStatus={formik.errors.lugartrabajo && formik.touched.lugartrabajo ? "error" : ""}
                    help={formik.touched.lugartrabajo && formik.errors.lugartrabajo
                    ? formik.errors.lugartrabajo
                    : ""}
                >
                    <Input
                    name="lugartrabajo"
                    value={formik.values.lugartrabajo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} />
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item
                    label="Dirección"
                    validateStatus={formik.errors.direccion && formik.touched.direccion ? "error" : ""}
                    help={formik.touched.direccion && formik.errors.direccion
                    ? formik.errors.direccion
                    : ""}
                >
                    <Input
                    name="direccion"
                    value={formik.values.direccion}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} />
                </Form.Item>
            </Col>
            </Row>

            <Row gutter={24}>
            <Col span={5}>
                <Form.Item
                    label="No. trabajadores"
                    validateStatus={formik.errors.num_trabajadores && formik.touched.num_trabajadores ? "error" : ""}
                    help={formik.touched.num_trabajadores && formik.errors.num_trabajadores
                    ? formik.errors.num_trabajadores
                    : ""}
                >
                    <Input
                        name="num_trabajadores"
                        value={formik.values.num_trabajadores}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur} 
                    />    
                </Form.Item>
            </Col>
            <Col span={5}>
                <Form.Item
                    label="No. llamadas de servicios"
                    validateStatus={formik.errors.num_llamada_servicios && formik.touched.num_llamada_servicios ? "error" : ""}
                    help={formik.touched.num_llamada_servicios && formik.errors.num_llamada_servicios
                    ? formik.errors.num_llamada_servicios
                    : ""}
                >
                    <Input
                        name="num_llamada_servicios"
                        value={formik.values.num_llamada_servicios}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur} 
                    />    
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item
                    label="Herramienta / Equipo"
                    validateStatus={formik.errors.herramienta && formik.touched.herramienta ? "error" : ""}
                    help={formik.touched.herramienta && formik.errors.herramienta
                    ? formik.errors.herramienta
                    : ""}
                >
                    <Input
                    name="herramienta"
                    value={formik.values.herramienta}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} />
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item
                    label="Trabajo a realizar"
                    validateStatus={formik.errors.trabajo && formik.touched.trabajo ? "error" : ""}
                    help={formik.touched.trabajo && formik.errors.trabajo
                    ? formik.errors.trabajo
                    : ""}
                >
                    <Input
                    name="trabajo"
                    value={formik.values.trabajo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} />
                </Form.Item>
            </Col>
            </Row>
            <Row gutter={24}>
            { formik.values.tipoformulario !== "ATS" && (
            <Col span={7}>
                <Form.Item
                    label="Requiere permisos de trabajo"
                    validateStatus={formik.errors.permisotrabajo && formik.touched.permisotrabajo ? "error" : ""}
                    help={formik.touched.permisotrabajo && formik.errors.permisotrabajo
                    ? formik.errors.permisotrabajo
                    : ""}
                >
                    <Select
                        name="permisotrabajo"
                        value={formik.values.permisotrabajo}
                        onChange={(value) => formik.setFieldValue('permisotrabajo', value)} // Actualiza el valor en formik
                        onBlur={formik.handleBlur}
                        placeholder="Selecciona una opción"
                        defaultValue={false}
                    >
                        <Option value="true">Sí</Option>
                        <Option value="false">No</Option>
                    </Select>
                </Form.Item>
            </Col>
            )}
            { formik.values.permisotrabajo === 'true' &&  (
            <Col span={6}>
                <Form.Item
                    label="No. permiso de trabajo"
                    validateStatus={formik.errors.num_permiso && formik.touched.num_permiso ? "error" : ""}
                    help={formik.touched.num_permiso && formik.errors.num_permiso
                    ? formik.errors.num_permiso
                    : ""}
                >
                    <Input
                    name="num_permiso"
                    value={formik.values.num_permiso}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} />
                </Form.Item>
            </Col>
            )}
            { formik.values.tipoformulario !== 'ATS' &&  (
            <Col span={8}>
                <Form.Item
                    label="ID de ATS a vincular"
                    validateStatus={formik.errors.Id_ats && formik.touched.Id_ats ? "error" : ""}
                    help={formik.touched.Id_ats && formik.errors.Id_ats
                    ? formik.errors.Id_ats
                    : ""}
                >
                    <Select
                    name="Id_ats"
                    value={formik.values.Id_ats}
                    onChange={(value) => formik.setFieldValue('Id_ats', value)} // Actualiza el valor en formik
                    onBlur={formik.handleBlur}
                    placeholder="Selecciona una opción"
                    >
                    {FormularioSig && !FormularioSig.error && FormularioSig?.filter(formulario => formulario.tipoformulario === "ATS").map(formulario => (
                        <Option key={formulario.Id_formulario} value={formulario.Id_formulario}>
                            ID ATS: {formulario.Id_formulario}
                        </Option>
                    ))}
                    </Select>
                </Form.Item>
            </Col>
            )}
            { formik.values.tipoformulario !== 'ATS' &&  (
            <Col span={6}>
                <Form.Item
                    label="Altura o profundidad (mt)"
                    validateStatus={formik.errors.altura && formik.touched.altura ? "error" : ""}
                    help={formik.touched.altura && formik.errors.altura
                    ? formik.errors.altura
                    : ""}
                >
                    <Input
                    name="altura"
                    value={formik.values.altura}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} />
                </Form.Item>
            </Col>
            )}
            { formik.values.tipoformulario !== 'ATS' &&  formik.values.fecha_elaboracion &&  (
            <Col span={6}>
                <Form.Item
                    label="Fecha Terminación"
                    validateStatus={formik.errors.Fechaterminacion && formik.touched.Fechaterminacion
                    ? "error"
                    : ""}
                    help={formik.touched.Fechaterminacion && formik.errors.Fechaterminacion
                    ? formik.errors.Fechaterminacion
                    : ""}
                >
                    <DatePicker
                        showTime
                        name="Fechaterminacion"
                        style={{ width: '100%' }}
                        value={formik.values.Fechaterminacion ? moment(formik.values.Fechaterminacion) : null}
                        onChange={(date, dateString) => formik.setFieldValue('Fechaterminacion', dateString)}
                        onBlur={() => formik.setFieldTouched('Fechaterminacion')}
                        disabledDate={disabledDate}
                    />
                </Form.Item>
            </Col>
            )}
            </Row>

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Crear
                        </Button>
                    </Form.Item>
                </div>
            </>
            )}
        </Form>
    </>
    );
}

export function DatosActividadFormUpdate(props) {

    const { FormularioSigUpdate, handleNext, dataTipoFormulario, idFormularioPrincipal, setnewData } = props;
    const { updateFormularioSig, FormularioSig, getFormularioSig } = useFormularioSig();
    const { Option } = Select;
    const { auth } = useAuth();
    const creador_name = auth.me?.full_name;
    const id_user = auth.me?.id_user;
    
    const formikUpdate = useFormik({
        initialValues: initialValuesDatosActividad(FormularioSigUpdate, id_user, creador_name),
        onSubmit: async (formValues) => {

            if (formValues.permisotrabajo === 'No') {
                formValues.num_permiso = "";
            }

            if(formValues.permisotrabajo === 'Si'){
                formValues.permisotrabajo = true
                formikUpdate.setFieldValue('permisotrabajo', 'Si')
            }

            if(formValues.permisotrabajo === 'No'){
                formValues.permisotrabajo = false
                formikUpdate.setFieldValue('permisotrabajo', 'No')
            }

            try {
                await updateFormularioSig(idFormularioPrincipal, formValues);
                setnewData(formValues)
                message.success("Actualización exitosa (Datos actividad)");
                handleNext();
            } catch (error) {
                message.error("Error en la operación (Datos actividad)");
            }
        },
    });

    const disabledDate = (current) => {

        const startDate = formikUpdate.values.fecha_elaboracion;

        //Los valores que definen los dias Sabados es 6 y los domingos es 0
        //

        if (!startDate) {
          //return current && (current.day() === 6 || current.day() === 0); // Deshabilitar sábados y domingos si no hay fecha de inicio
          return current && current.day() === 0; // Deshabilitar sábados y domingos si no hay fecha de inicio
        }

        const minDate = moment(startDate).add(1, "days");
    
        let countBusinessDays = 0;
        let tempDate = moment(startDate);
    
        // Iterar hasta encontrar 6 días hábiles (lunes a viernes)
        while (countBusinessDays < 8) {
          if (tempDate.day() !== 8 && tempDate.day() !== 0) {
            countBusinessDays++; // Contar el día hábil encontrado
          }
    
          // Avanzar al siguiente día
          tempDate.add(1, "days");
        }
    
        // endDate será el último día hábil encontrado
        const endDate = tempDate;
    
        return (
          // Deshabilitar sábados y domingos o fechas fuera del rango permitido
          //(current && (current.day() === 6 || current.day() === 0)) || // Sábado (6) o domingo (0)
          (current && current.day() === 0) || // domingo (0)
          (current && (current.isBefore(minDate) || current.isAfter(endDate))) // Fuera del rango de fecha permitido
        );
    };

    console.log(formikUpdate.values)

    return(
        <Form onFinish={formikUpdate.handleSubmit} layout="vertical">
            <Row gutter={16}>
            <Col span={8}>
                <Form.Item
                    label="Fecha Elaboración"
                    validateStatus={formikUpdate.errors.fecha_elaboracion && formikUpdate.touched.fecha_elaboracion
                    ? "error"
                    : ""}
                    help={formikUpdate.touched.fecha_elaboracion && formikUpdate.errors.fecha_elaboracion
                    ? formikUpdate.errors.fecha_elaboracion
                    : ""}
                >
                        <DatePicker
                            showTime
                            name="fecha_elaboracion"
                            style={{width: '100%'}}
                            value={(formikUpdate.values.fecha_elaboracion ? moment(formikUpdate.values.fecha_elaboracion) : null)}
                            onChange={(date, dateString) => formikUpdate.setFieldValue('fecha_elaboracion', dateString)}
                            onBlur={() => formikUpdate.setFieldTouched('fecha_elaboracion')} 
                        />
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item
                    label="Fecha de Inicio"
                    validateStatus={formikUpdate.errors.fecha_inicio && formikUpdate.touched.fecha_inicio
                    ? "error"
                    : ""}
                    help={formikUpdate.touched.fecha_inicio && formikUpdate.errors.fecha_inicio
                    ? formikUpdate.errors.fecha_inicio
                    : ""}
                >
                    <DatePicker
                        showTime
                        name="fecha_inicio"
                        style={{ width: '100%' }}
                        value={formikUpdate.values.fecha_inicio ? moment(formikUpdate.values.fecha_inicio) : null}
                        onChange={(date, dateString) => formikUpdate.setFieldValue('fecha_inicio', dateString)}
                        onBlur={() => formikUpdate.setFieldTouched('fecha_inicio')} 
                    />
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item
                    label="Fecha Final"
                    validateStatus={formikUpdate.errors.fecha_final && formikUpdate.touched.fecha_final
                    ? "error"
                    : ""}
                    help={formikUpdate.touched.fecha_final && formikUpdate.errors.fecha_final
                    ? formikUpdate.errors.fecha_final
                    : ""}
                >
                    <DatePicker
                        showTime
                        name="fecha_final"
                        style={{ width: '100%' }}
                        value={formikUpdate.values.fecha_final ? moment(formikUpdate.values.fecha_final) : null}
                        onChange={(date, dateString) => formikUpdate.setFieldValue('fecha_final', dateString)}
                        onBlur={() => formikUpdate.setFieldTouched('fecha_final')} 
                    />
                </Form.Item>
            </Col>
            </Row>

            <Row gutter={24}>   
            <Col span={7}>
                <Form.Item
                    label="No. Orden Servicio / Proyecto"
                    validateStatus={formikUpdate.errors.num_orden && formikUpdate.touched.num_orden ? "error" : ""}
                    help={formikUpdate.touched.num_orden && formikUpdate.errors.num_orden
                    ? formikUpdate.errors.num_orden
                    : ""}
                >
                    <Input
                    name="num_orden"
                    value={formikUpdate.values.num_orden}
                    onChange={formikUpdate.handleChange}
                    onBlur={formikUpdate.handleBlur} />
                </Form.Item>
            </Col>
            <Col span={5}>
                <Form.Item
                    label="Empresa"
                    validateStatus={formikUpdate.errors.empresa && formikUpdate.touched.empresa ? "error" : ""}
                    help={formikUpdate.touched.empresa && formikUpdate.errors.empresa
                    ? formikUpdate.errors.empresa
                    : ""}
                >
                    <Input
                    name="empresa"
                    value={formikUpdate.values.empresa}
                    onChange={formikUpdate.handleChange}
                    onBlur={formikUpdate.handleBlur} />
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item
                    label="Lugar de trabajo"
                    validateStatus={formikUpdate.errors.lugartrabajo && formikUpdate.touched.lugartrabajo ? "error" : ""}
                    help={formikUpdate.touched.lugartrabajo && formikUpdate.errors.lugartrabajo
                    ? formikUpdate.errors.lugartrabajo
                    : ""}
                >
                    <Input
                    name="lugartrabajo"
                    value={formikUpdate.values.lugartrabajo}
                    onChange={formikUpdate.handleChange}
                    onBlur={formikUpdate.handleBlur} />
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item
                    label="Dirección"
                    validateStatus={formikUpdate.errors.direccion && formikUpdate.touched.direccion ? "error" : ""}
                    help={formikUpdate.touched.direccion && formikUpdate.errors.direccion
                    ? formikUpdate.errors.direccion
                    : ""}
                >
                    <Input
                    name="direccion"
                    value={formikUpdate.values.direccion}
                    onChange={formikUpdate.handleChange}
                    onBlur={formikUpdate.handleBlur} />
                </Form.Item>
            </Col>
            </Row>

            <Row gutter={24}>
            <Col span={5}>
                <Form.Item
                    label="No. trabajadores"
                    validateStatus={formikUpdate.errors.num_trabajadores && formikUpdate.touched.num_trabajadores ? "error" : ""}
                    help={formikUpdate.touched.num_trabajadores && formikUpdate.errors.num_trabajadores
                    ? formikUpdate.errors.num_trabajadores
                    : ""}
                >
                    <Input
                    name="num_trabajadores"
                    value={formikUpdate.values.num_trabajadores}
                    onChange={formikUpdate.handleChange}
                    onBlur={formikUpdate.handleBlur} />
                </Form.Item>
            </Col>
            <Col span={5}>
                <Form.Item
                    label="No. llamadas de servicios"
                    validateStatus={formikUpdate.errors.num_llamada_servicios && formikUpdate.touched.num_llamada_servicios ? "error" : ""}
                    help={formikUpdate.touched.num_llamada_servicios && formikUpdate.errors.num_llamada_servicios
                    ? formikUpdate.errors.num_llamada_servicios
                    : ""}
                >
                    <Input
                        name="num_llamada_servicios"
                        value={formikUpdate.values.num_llamada_servicios}
                        onChange={formikUpdate.handleChange}
                        onBlur={formikUpdate.handleBlur} 
                    />    
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item
                    label="Herramienta / Equipo"
                    validateStatus={formikUpdate.errors.herramienta && formikUpdate.touched.herramienta ? "error" : ""}
                    help={formikUpdate.touched.herramienta && formikUpdate.errors.herramienta
                    ? formikUpdate.errors.herramienta
                    : ""}
                >
                    <Input
                    name="herramienta"
                    value={formikUpdate.values.herramienta}
                    onChange={formikUpdate.handleChange}
                    onBlur={formikUpdate.handleBlur} />
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item
                    label="Trabajo a realizar"
                    validateStatus={formikUpdate.errors.trabajo && formikUpdate.touched.trabajo ? "error" : ""}
                    help={formikUpdate.touched.trabajo && formikUpdate.errors.trabajo
                    ? formikUpdate.errors.trabajo
                    : ""}
                >
                    <Input
                        name="trabajo"
                        value={formikUpdate.values.trabajo}
                        onChange={(e) => formikUpdate.handleChange(e)} // Asegúrate de que handleChange esté manejando el evento correctamente
                        onBlur={(e) => formikUpdate.handleBlur(e)} // Asegúrate de que handleBlur esté manejando el evento correctamente
                    />
                </Form.Item>
            </Col>
            </Row>

            <Row gutter={24}>
            
            { dataTipoFormulario !== 'ATS' &&  (
            <Col span={8}>
                <Form.Item
                    label="ID de ATS a vincular"
                    validateStatus={formikUpdate.errors.Id_ats && formikUpdate.touched.Id_ats ? "error" : ""}
                    help={formikUpdate.touched.Id_ats && formikUpdate.errors.Id_ats
                    ? formikUpdate.errors.Id_ats
                    : ""}
                >
                    <Select
                    name="Id_ats"
                    value={formikUpdate.values.Id_ats}
                    onChange={(value) => formikUpdate.setFieldValue('Id_ats', value)} // Actualiza el valor en formikUpdate
                    onBlur={formikUpdate.handleBlur}
                    placeholder="Selecciona una opción"
                    >
                    {FormularioSig && !FormularioSig.error && FormularioSig.map(formulario => (
                        <Option key={formulario.Id_formulario} value={formulario.Id_formulario}>
                            ID ATS: {formulario.Id_formulario}
                        </Option>
                    ))}
                    </Select>
                </Form.Item>
            </Col>
            )}
            <Col span={7}>
                <Form.Item
                    label="Requiere permisos de trabajo"
                    validateStatus={formikUpdate.errors.permisotrabajo && formikUpdate.touched.permisotrabajo ? "error" : ""}
                    help={formikUpdate.touched.permisotrabajo && formikUpdate.errors.permisotrabajo
                    ? formikUpdate.errors.permisotrabajo
                    : ""}
                >
                    <Select
                        name="permisotrabajo"
                        value={formikUpdate.values.permisotrabajo}
                        onChange={(value) => formikUpdate.setFieldValue('permisotrabajo', value)} // Actualiza el valor en formik
                        onBlur={formikUpdate.handleBlur}
                        placeholder="Selecciona una opción"
                    >
                        <Option value={true}>Si</Option>
                        <Option value={false}>No</Option>
                    </Select>
                </Form.Item>
            </Col>
            { (formikUpdate.values.permisotrabajo === true || formikUpdate.values.permisotrabajo === 'Si') &&  (
            <Col span={6}>
                <Form.Item
                    label="No. permiso de trabajo"
                    validateStatus={formikUpdate.errors.num_permiso && formikUpdate.touched.num_permiso ? "error" : ""}
                    help={formikUpdate.touched.num_permiso && formikUpdate.errors.num_permiso
                    ? formikUpdate.errors.num_permiso
                    : ""}
                >
                    <Input
                    name="num_permiso"
                    value={formikUpdate.values.num_permiso}
                    onChange={formikUpdate.handleChange}
                    onBlur={formikUpdate.handleBlur} />
                </Form.Item>
            </Col>
            )}
            { formikUpdate.values.tipoformulario !== 'ATS' &&  (
            <Col span={6}>
                <Form.Item
                    label="Altura o profundidad (mt)"
                    validateStatus={formikUpdate.errors.altura && formikUpdate.touched.altura ? "error" : ""}
                    help={formikUpdate.touched.altura && formikUpdate.errors.altura
                    ? formikUpdate.errors.altura
                    : ""}
                >
                    <Input
                    name="altura"
                    value={formikUpdate.values.altura}
                    onChange={formikUpdate.handleChange}
                    onBlur={formikUpdate.handleBlur} />
                </Form.Item>
            </Col>
            )}
            { formikUpdate.values.tipoformulario !== 'ATS' &&  (
            <Col span={6}>
                <Form.Item
                    label="Fecha Terminación"
                    validateStatus={formikUpdate.errors.Fechaterminacion && formikUpdate.touched.Fechaterminacion
                    ? "error"
                    : ""}
                    help={formikUpdate.touched.Fechaterminacion && formikUpdate.errors.Fechaterminacion
                    ? formikUpdate.errors.Fechaterminacion
                    : ""}
                >
                    <DatePicker
                        showTime
                        name="Fechaterminacion"
                        style={{ width: '100%' }}
                        value={formikUpdate.values.Fechaterminacion ? moment(formikUpdate.values.Fechaterminacion) : null}
                        onChange={(date, dateString) => formikUpdate.setFieldValue('Fechaterminacion', dateString)}
                        onBlur={() => formikUpdate.setFieldTouched('Fechaterminacion')}
                        disabledDate={disabledDate}
                    />
                </Form.Item>
            </Col>
            )}
            </Row>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Actualizar
                    </Button>
                </Form.Item>
            </div>
        </Form>
    );
}

export function DatosActividadFullView(props){
    const { data, dataTipoFormulario } = props;
    const { getFormularioSig } = useFormularioSig();
    const { auth } = useAuth();
    const creador_name = auth.me?.full_name;
    const id_user = auth.me?.id_user;

    useEffect(() => {
        getFormularioSig();
    }, []);

    const formikView = useFormik({
        initialValues: initialValuesDatosActividad(data, id_user, creador_name),
    });

    const columns = [
        { dataIndex: 'campo1', key: 'campo1', width: '20%', render: text => <div style={{ fontSize: '12px' }}>{text}</div> },
        { dataIndex: 'campo2', key: 'campo2', width: '20%', render: text => <div style={{ fontSize: '12px' }}>{text}</div> },
        { dataIndex: 'campo3', key: 'campo3', width: '20%', render: text => <div style={{ fontSize: '12px' }}>{text}</div> },
        { dataIndex: 'campo4', key: 'campo4', width: '20%', render: text => <div style={{ fontSize: '12px' }}>{text}</div> },
        { dataIndex: 'campo5', key: 'campo5', width: '20%', render: text => <div style={{ fontSize: '12px' }}>{text}</div> },
    ];
    
    const dataSource = [
        {
            key: '1',
            campo1: 'Fecha Elaboración',
            campo2: 'Fecha Inicio',
            campo3: 'Fecha Final',
            campo4: 'Dirección',
            campo5: 'No. Orden Servicio / Proyecto',
        },
        {
            key: '2',
            campo1: formikView.values.fecha_elaboracion ? moment(formikView.values.fecha_elaboracion).format('DD/MM/YYYY HH:mm:ss') : '',
            campo2: formikView.values.fecha_inicio ? moment(formikView.values.fecha_inicio).format('DD/MM/YYYY HH:mm:ss') : '',
            campo3: formikView.values.fecha_final ? moment(formikView.values.fecha_final).format('DD/MM/YYYY HH:mm:ss') : '',
            campo4: formikView.values.direccion,
            campo5: formikView.values.num_orden,
        },
        {
            key: '3',
            campo1: 'Empresa',
            campo2: 'Lugar de trabajo',
            campo3: 'Dirección',
            campo4: 'No. trabajadores',
            campo5: 'No. llamada de servicios',
        },
        {
            key: '4',
            campo1: formikView.values.empresa,
            campo2: formikView.values.lugartrabajo,
            campo3: formikView.values.direccion,
            campo4: formikView.values.num_trabajadores,
            campo5: formikView.values.num_llamada_servicios,
        },
        {
            key: '5',
            
            campo1: 'Herramienta / Equipo',
            campo2: 'Trabajo a realizar',
            campo3: 'ID de ATS a vincular',
            campo4: 'Requiere permisos de trabajo',
            campo5: 'No. permiso de trabajo',
        },
        {
            key: '6',
            
            campo1: formikView.values.herramienta,
            campo2: formikView.values.trabajo,
            campo3: formikView.values.tipoformulario !== 'ATS' ? formikView.values.Id_ats : '',
            campo4: formikView.values.permisotrabajo,
            campo5: formikView.values.permisotrabajo === 'true' ? formikView.values.num_permiso : '',
        },
        {
            key: '7',
            campo1: 'Altura o profundidad (mt)',
            campo2: 'Fecha Terminación',
        },
        {
            key: '8',
            campo1: formikView.values.tipoformulario !== 'ATS' ? formikView.values.altura : '',
            campo2: formikView.values.tipoformulario !== 'ATS' ? formikView.values.Fechaterminacion ? moment(formikView.values.Fechaterminacion).format('DD/MM/YYYY HH:mm:ss') : '' : '',
        }
    ];


    // Función para determinar la clase de la fila
    const getRowClassName = (record) => {
        const grayKeys = ['1', '3', '5', '7']; // Claves para las filas que tendrán fondo gris
        return grayKeys.includes(record.key) ? 'gray-background' : '';
    };


    return(
        <>
            <Row style={{ fontSize: '25px', fontWeight: 'bold', color: 'rgb(204, 48, 43)' }}>
                Datos iniciales
            </Row>
            <hr/>
            <Table
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                showHeader={false}
                bordered
                rowClassName={getRowClassName}
                className="table-narrow"
            />
        </>
    );
}