import React from 'react';
import { Modal, Box, Typography, IconButton, Icon } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const FilePreviewModal = ({ open, handleClose, url }) => {

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="file-preview-modal"
      aria-describedby="modal-to-preview-file"
    >
      <Box sx={modalStyle}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" component="h2">
          Previsualización del archivo
        </Typography>
        {url === null ? (
          <p>El archivo no aplica para este tipo de permiso</p>
        ) : (
          <iframe
            src={url}
            title="File Viewer"
            style={{ width: '100%', height: '100%', border: 'none' }}
          />
        )}

      </Box>
    </Modal>
  );
};

export default FilePreviewModal;