import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Button,
  Card,
  Container,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import { Dropdown, Menu, message } from "antd";
import CustomPopover from "./CustomPopover";
import { CellStyle, ButtonStyle } from "./styles";
import { useAuth } from "../../hooks/useAuth";

export function TablaFormulariosSig(props) {
  const {
    Data,
    handleAgregarData,
    handleEditarData,
    handleChangeStatus,
    handleViewData,
    handleAprobacionDifundido,
    handleAprobacionAprobador,
    columns,
    popoverColumns,
  } = props;

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [popoverAnchorMas, setPopoverAnchorMas] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [popoverMasId, setPopoverMasId] = useState(null); // Definir popoverMasId
  const { auth } = useAuth();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const cancelDeletePop = () => {
    message.success("Operación cancelada");
  };

  const filteredData =
    Array.isArray(Data) &&
    Data.filter((data) =>
      columns.some((header) =>
        data[header.field]
          ?.toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    );

  // Ordenar los datos en base al estado de manera personalizada
  const sortedData = [...filteredData].sort((a, b) => {
    const stateOrder = { 1: 1, 5: 2, 3: 3, 2: 4, 4: 5 };
    return stateOrder[a.estado] - stateOrder[b.estado];
  });

  const rowsToShow =
  sortedData &&
  sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const getColorAndTextStyle = (estado) => {
    let style = {
      color: "white",
      width: "120px", // Ancho por defecto para todos los estados
    };
  
    switch (estado) {
      case 1:
        style.backgroundColor = "rgb(8, 19, 26)";
        style.text = "Pendiente";
        break;
      case 2:
        style.backgroundColor = "#60C000";
        style.text = "Aprobado";
        break;
      case 3:
        style.backgroundColor = "rgb(68, 68, 68)";
        style.text = "Rechazado";
        break;
      case 4:
        style.backgroundColor = "rgb(204, 48, 43)";
        style.text = "Anulado";
        break;
      case 5:
        style.backgroundColor = "rgb(195, 195, 2)";
        style.text = "Aprobado Parcial";
        break;
      default:
        break;
    }
    
  
    return style;
  };
    
  const handlePopoverMasOpen = (event, Data) => {
    setPopoverAnchorMas(event.currentTarget);
    setSelectedData(Data);
    setPopoverMasId(event.currentTarget.id); // Asignar el ID del popover
  };

  const handlePopoverMasClose = () => {
    setPopoverAnchorMas(null);
    setSelectedData(null);
    setPopoverMasId(null); // Limpiar el ID del popover al cerrar
  };

  const openMas = Boolean(popoverAnchorMas);

  return (
    <Container>
      <Card
        sx={{
          borderRadius: "12px",
          padding: "16px",
          marginBottom: "16px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TextField
          label="Buscar dato"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        {handleAgregarData && (
          <Button
            variant="contained"
            style={ButtonStyle}
            startIcon={<AddIcon />}
            onClick={() => handleAgregarData(selectedData)}
          >
            Agregar
          </Button>
        )}
      </Card>
      <Card>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                {columns.map((header) => (
                  <TableCell key={header.field}>
                    <Typography style={CellStyle}>{header.label}</Typography>
                  </TableCell>
                ))}
                <TableCell>
                  <Typography style={CellStyle}>Estado</Typography>
                </TableCell>
                <TableCell>
                  <Typography style={CellStyle}>Más datos</Typography>
                </TableCell>
                <TableCell>
                  <Typography style={CellStyle}>Visualizar datos</Typography>
                </TableCell>
                {handleAprobacionAprobador && handleAprobacionDifundido && (
                  <TableCell>
                    <Typography style={CellStyle}>Aprobaciones</Typography>
                  </TableCell>
                )}
                <TableCell>
                  <Typography  style={CellStyle}>Opciones</Typography>
                </TableCell>
              </TableRow>
              {rowsToShow &&
                rowsToShow.map((data) => (
                  <TableRow key={data.id}>
                    {columns.map((header) => (
                      <TableCell style={CellStyle} key={header.field}>
                        {data[header.field]}
                      </TableCell>
                    ))}
                    <TableCell
                      style={{
                        textAlign: "center",
                        fontSize: "11px",
                        CellStyle,
                        ...{
                          backgroundColor: getColorAndTextStyle(data.estado).backgroundColor,
                          color: getColorAndTextStyle(data.estado).color,
                          width: getColorAndTextStyle(data.estado).width,
                        },
                      }}
                    >
                      {getColorAndTextStyle(data.estado).text}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={(event) => handlePopoverMasOpen(event, data)}>
                        <AddIcon />
                      </IconButton>
                    </TableCell>
                    {handleViewData && (
                      <TableCell>
                        <center>
                          <IconButton>
                            <VisibilityIcon onClick={() => handleViewData(data)} />
                          </IconButton>
                        </center>
                      </TableCell>
                    )}

                    

                    {handleAprobacionAprobador && handleAprobacionDifundido && (
                      <TableCell>
                        <center>
                          <Dropdown
                            overlay={
                              <Menu>
                                {handleAprobacionDifundido && (
                                  <Menu.Item onClick={() => handleAprobacionDifundido(data.Id_difundidoa)}>Solicitar aprobacion difundidos</Menu.Item>
                                )}
                                {handleAprobacionAprobador && (
                                  <Menu.Item onClick={() => handleAprobacionAprobador(data.Id_supervisor)}>Solicitar aprobacion SST</Menu.Item>
                                )}
                              </Menu>
                              }
                              trigger={["click"]}
                          >                       
                            <IconButton>
                              <MoreVertIcon />
                            </IconButton>
                          </Dropdown>
                        </center>
                      </TableCell>
                    )}

                    {handleEditarData && (
                      <TableCell>
                        <center>
                          <Dropdown
                            overlay={
                              <Menu>
                              { (data.estado === 1 || data.estado === 3 || data.estado === 5) && (
                                <Menu.Item onClick={() => handleEditarData(data)}>Editar</Menu.Item> ) || <Menu.Item>No se puede editar</Menu.Item>}
                              </Menu>

                            }
                            trigger={["click"]}
                          >
                            <IconButton>
                              <MoreVertIcon />
                            </IconButton>
                          </Dropdown>
                        </center>
                      </TableCell>
                    )}
                   {handleChangeStatus && (
                      <TableCell>
                        <center>
                          <Dropdown
                            overlay={
                              <Menu>
                                  <Menu.Item onClick={() => handleChangeStatus(data)}>Cambiar estado</Menu.Item>
                              </Menu>
                            }
                            trigger={["click"]}
                          >
                            <IconButton>
                              <MoreVertIcon />
                            </IconButton>
                          </Dropdown>
                        </center>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredData ? filteredData.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      <CustomPopover
        id={popoverMasId}
        open={openMas}
        anchorEl={popoverAnchorMas}
        onClose={handlePopoverMasClose}
        selectedData={selectedData}
        Columns={popoverColumns}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
        message="URL copiada con éxito"
      />
    </Container>
  );
}


export function TablaFormulariosSigMe(props) {
  const {
    Data,
    handleAgregarData,
    handleChangeStatus,
    columns,
    popoverColumns,
  } = props;

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [popoverAnchorMas, setPopoverAnchorMas] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [popoverMasId, setPopoverMasId] = useState(null); // Definir popoverMasId
  const { auth } = useAuth();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };


  const filteredData =
    Array.isArray(Data) &&
    Data.filter((data) =>
      columns.some((header) =>
        data[header.field]
          ?.toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    );

  const rowsToShow = filteredData && filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const getColorAndTextStyle = (estado) => {
    let style = {
      color: "white",
      width: "120px", // Ancho por defecto para todos los estados
    };
  
    switch (estado) {
      case 'Pendiente':
        style.backgroundColor = "rgb(8, 19, 26)";
        style.text = "Pendiente";
        break;
      case 'Aprobado':
        style.backgroundColor = "#60C000";
        style.text = "Aprobado";
        break;
      case 'Rechazado':
        style.backgroundColor = "rgb(68, 68, 68)";
        style.text = "Rechazado";
        break;
      default:
        break;
    }
    
  
    return style;
  };
    
  const handlePopoverMasOpen = (event, Data) => {
    setPopoverAnchorMas(event.currentTarget);
    setSelectedData(Data);
    setPopoverMasId(event.currentTarget.id); // Asignar el ID del popover
  };

  const handlePopoverMasClose = () => {
    setPopoverAnchorMas(null);
    setSelectedData(null);
    setPopoverMasId(null); // Limpiar el ID del popover al cerrar
  };

  const openMas = Boolean(popoverAnchorMas);

  return (
    <Container>
      <Card
        sx={{
          borderRadius: "12px",
          padding: "16px",
          marginBottom: "16px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TextField
          label="Buscar dato"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        {handleAgregarData && (
          <Button
            variant="contained"
            style={ButtonStyle}
            startIcon={<AddIcon />}
            onClick={() => handleAgregarData(selectedData)}
          >
            Agregar
          </Button>
        )}
      </Card>
      <Card>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                {columns.map((header) => (
                  <TableCell key={header.field}>
                    <Typography style={CellStyle}>{header.label}</Typography>
                  </TableCell>
                ))}
                <TableCell>
                  <Typography style={CellStyle}>Estado</Typography>
                </TableCell>
                <TableCell>
                  <Typography style={CellStyle}>Ver mas</Typography>
                </TableCell>
                <TableCell>
                  <Typography  style={CellStyle}>Opciones</Typography>
                </TableCell>
              </TableRow>
              {rowsToShow &&
                rowsToShow.map((data) => (
                  <TableRow key={data.id}>
                    {columns.map((header) => (
                      <TableCell style={CellStyle} key={header.field}>
                        {data[header.field]}
                      </TableCell>
                    ))}
                    <TableCell
                      style={{
                        textAlign: "center",
                        fontSize: "11px",
                        CellStyle,
                        ...{
                          backgroundColor: getColorAndTextStyle(data.difundidoEstado[0]).backgroundColor,
                          color: getColorAndTextStyle(data.difundidoEstado[0]).color,
                          width: getColorAndTextStyle(data.difundidoEstado[0]).width,
                        },
                      }}
                    >
                      {getColorAndTextStyle(data.difundidoEstado[0]).text}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={(event) => handlePopoverMasOpen(event, data)}>
                        <AddIcon />
                      </IconButton>
                    </TableCell>
                   {handleChangeStatus && (
                      <TableCell>
                        <Dropdown
                          overlay={
                            <Menu key="">
                                <Menu.Item onClick={() => handleChangeStatus(data)}>Cambiar estado</Menu.Item>
                            </Menu>
                          }
                          trigger={["click"]}
                        >
                          <IconButton>
                            <MoreVertIcon />
                          </IconButton>
                        </Dropdown>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredData ? filteredData.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      <CustomPopover
        id={popoverMasId}
        open={openMas}
        anchorEl={popoverAnchorMas}
        onClose={handlePopoverMasClose}
        selectedData={selectedData}
        Columns={popoverColumns}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
        message="URL copiada con éxito"
      />
    </Container>
  );
}